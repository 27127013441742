import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { CourseTypes } from 'src/app/_models/CourseTypes';
import { Exam } from 'src/app/_models/Exam';
import { Level } from 'src/app/_models/Level';
import { CourseInsertUpdate } from 'src/app/_models/Request/CourseInsertUpdate';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { CourseService } from 'src/app/_services/course.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrls: ['./course-edit.component.css']
})
export class CourseEditComponent implements OnInit {
  updateForm: FormGroup;
  course: CourseInsertUpdate;
  courseTypes: CourseTypes[];
  Classifications: Classification[];
  levels: Level[];
  exams: Exam[];

  constructor(private router: Router, private route: ActivatedRoute,
              private alertify: AlertifyService, private fb: FormBuilder,
              private courseService: CourseService, private examService: ExamService) { }

  ngOnInit() {
    
    this.route.data.subscribe(data => {
      this.course = data.Course;
    });
    this.createUpdateForm();
    this.loadLevels();
    this.loadCourseExams();
    this.getCourseTypes();
  }

  loadCourseExams() {
    const CourseExams = this.updateForm.get('CourseExams') as FormArray;
    this.course.CourseExams.forEach(element => {
      CourseExams.push(this.createCourseExamsFrom(element.idExam, element.examName));
    });
  }
  getCourseTypes() {
    this.courseService.GetCourseTypes().subscribe((courseTypes: CourseTypes[]) => {
      this.courseTypes = courseTypes;
    }, error => {
      this.alertify.error(error);
    });
  }

  createUpdateForm() {
    this.updateForm = this.fb.group({
      Active: [this.course.Active , Validators.required],
      Name: [this.course.Name , Validators.required],
      Description: [this.course.Description, Validators.required],
      Price: [this.course.Price, Validators.required],
      Duration: [this.course.Duration, Validators.required],
      Modality: [this.course.Modality , Validators.required],
      CourseType: [this.course.CourseType, Validators.required],
      CourseExams: this.fb.array([])
    });
  }

  createCourseExamsFrom(idExam:number, examName:string): FormGroup{
    return new FormGroup ({
      idCourse: new FormControl(this.course.idCourses),
      idExam: new FormControl(idExam, Validators.required),
      examName: new FormControl(examName)
    });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  updateCourse(){
    Object.assign(this.course, this.updateForm.value);
    this.courseService.UpdateCourse(this.course).subscribe(() => {
      this.alertify.success('Curso Actualizado');
      this.router.navigate(['/courses']);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
    });
    this.updateForm.reset(this.updateForm.value);
  }

  // carga de datos
  loadLevels() {
    this.examService.getLevels().subscribe((levels: Level[]) => {
      this.levels = levels;
    }, error => {
      this.alertify.error(error);
    });
  }
  
  onChangeLevel(value){
    // cargamos las classificaciones
    this.examService.getClassifications(value).subscribe((classifications: Classification[]) => {
      this.Classifications = classifications;
    }, error => {
      this.alertify.error(error);
    });
  }

  onChangeClassification(value){
    let idLevel: number = +(<HTMLSelectElement>document.getElementById('Level')).value;
    // cargamos los examenes
    this.examService.GetExamsByLvlAndClas(idLevel, value).subscribe((exam: Exam[]) => {
      this.exams = exam;
    }, error => {
      this.alertify.error(error);
    });
  }

  addExam(){
    const CourseExams = this.updateForm.get('CourseExams') as FormArray;
    let idExam:number = +(<HTMLSelectElement>document.getElementById('ExamSelect')).value;
    if (idExam <= 0) {
      this.alertify.error('Favor de selecionar un examen');
      return;
    }
    else {
      let examName: string = this.exams[+(<HTMLSelectElement>document.getElementById('ExamSelect')).selectedIndex -1].Name;
      let elementExist = this.examAlredyAdded(idExam, examName);
      if(!elementExist) {
        CourseExams.push(this.createCourseExamsFrom(idExam, examName));
        this.updateForm.markAsDirty();
      }
    }
  }

  examAlredyAdded(idExam: number, examName: string): boolean{
    let exist: boolean = false;
    this.getFormArray().controls.forEach(element => {
      if (element.get('idExam').value === idExam) {
        this.alertify.error('El examen ' + examName + ' ya se agrego');
        exist = true;
      }
    });
    return exist;
  }
  
  public getFormArray(): FormArray{
    var form = this.updateForm.get('CourseExams') as FormArray;
    return form;
  }

  removeExam(id: number) {
    const CourseExams = this.updateForm.get('CourseExams') as FormArray;
    CourseExams.removeAt(id);
    this.updateForm.markAsDirty();
  }
}
