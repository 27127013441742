import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { User } from '../../_models/User';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Output() cancelRegister = new EventEmitter();
  user: User;
  registerForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private router: Router, private authService: AuthService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.bsConfig = {
      containerClass: 'theme-red'
    },
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      Gender: ['', Validators.required],
      Name: ['', Validators.required],
      LastName: ['', Validators.required],
      UserName: ['', Validators.required],
      Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(32)]],
      ConfirmPassword: ['', Validators.required],
      Email: ['', [Validators.required]],
      ConfirmEmail: ['', Validators.required],
      Profession: ['', Validators.required],
      DateOfBirth: ['', Validators.required],
      City: ['', Validators.required],
      Country: ['', Validators.required],
      AcademicDegree: ['', Validators.required],
      Subject: ['', Validators.required]
    }, {validator: [this.passwordMatchValidator, this.emailMatchValidator, this.userNameValidator]});
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('Password').value === g.get('ConfirmPassword').value ? null : {passDif: true};
  }

  emailMatchValidator(g: FormGroup) {
    return g.get('Email').value === g.get('ConfirmEmail').value ? null : {emailDif: true};
  }

  userNameValidator(g: FormGroup) {
    if (g.get('UserName').value != undefined){
      var re = /^(?=.{4,16}$)(?!.*[_]{2})[a-zA-Z0-9_]+$/g;
      let username: string = g.get('UserName').value;
      var myArray = username.match(re);
      if (myArray != undefined) {
        if (myArray.length > 1)
        {
          return {userInvalid: true};
        }
        else {
          return null;
        }
      }
      else {
        return {userInvalid: true};
      }
    }
    else{
      return null;
    }
  }

  register() {
    if (this.registerForm.valid) {
      this.user = Object.assign({}, this.registerForm.value);
      this.authService.regitser(this.user).subscribe(() => {
        this.alertify.success('Usuario registrado');
        this.cancelRegister.emit(false);
      }, error => {
        this.alertify.error(error);
      }, () => {
         //this.authService.login(this.user);
      });
    }
    //console.log(this.registerForm.value);
  }

  cancel() {
    this.cancelRegister.emit(false);
    this.alertify.message('cancelled');
  }
}
