import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {
  registerMode = false;
  images = [];
  
  constructor(config: NgbCarouselConfig) {
    config.interval = 3000;
    config.keyboard = true;
    //config.pauseOnHover = true;
    config.showNavigationArrows = true;
    config.pauseOnFocus = false;
    config.pauseOnHover = false;
   }

  ngOnInit() {
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/04/logo.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/04/logo2.jpg');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/b-2.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/05/curso-en-linea.jpeg');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/actualizacion2.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/d-2.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/e-2.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/f-2.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/g-2.png');
    this.images.push('https://inglesaprendeingles.files.wordpress.com/2021/06/actualizacion2.png');
    //this.images.push('C:/Users/BMXDDT007855/Videos/Bug_Fechas_Vigencia_Tarifa.mp4');
  }

  registerToggle() {
    this.registerMode = true;
  }

  cancelRegisterMode(registerMode: boolean) {
    this.registerMode = registerMode;
  }
}
