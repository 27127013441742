<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="updateForm" (ngSubmit)="updateExam()">
        <h2 class="text-center text-primary">Editar examen</h2>
        <hr>
        
        <h5>Nombre</h5>
        <div class="form-group">
          <input type="text" [(ngModel)]="exam.Name"
          [ngClass]="{'is-invalid': updateForm.get('Name').errors && updateForm.get('Name').touched }"
          class="form-control" formControlName="Name" placeholder="Nombre">
          <div class="invalid-feedback">Porfavor ingrese el nombre del examen</div>
        </div>

        <h5>Descripción(Instrucciones)</h5>
        <div class="form-group">
          <textarea [(ngModel)]="exam.Description" class="form-control"  rows="3" [ngClass]="{'is-invalid': updateForm.get('Description').errors && updateForm.get('Description').touched }"
          class="form-control" formControlName="Description" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <h5>Nivel</h5>
        <div class="form-group">
          <select [(ngModel)]="exam.IdLevel" class="custom-select" (change)="onChangeLevel($event.target.value)" [ngClass]="{'is-invalid': updateForm.get('idLevel').errors && updateForm.get('idLevel').touched }"
          class="form-control" formControlName="idLevel" placeholder="Nivel del examen">
          <option [value]="level.idLevel" *ngFor="let level of Levels">{{level.LevelName}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un nivel</div>
        </div>

        <div class="form-group">
          <div class="d-flex mb-3">
            <h5 class="text-center w-100">Clasificaciones</h5>
            <button class="btn btn-success" type="button" (click)="addClassification()"><i class="fa fa-plus"></i></button>
          </div>

          <div *ngFor="let item of getFormArray().controls | keyvalue; let i = index" formArrayName="Classifications">
            <div [formGroupName]="item.key">
              <div class="form-group ">
                 <div class="d-flex align-items-baseline w-100">
                    <div class="w-100">
                      <select class="custom-select"
                        [ngClass]="{'is-invalid': updateForm.get('Classifications').get(item.key).get('idClassification').errors 
                        && updateForm.get('Classifications').get(item.key).get('idClassification').touched }"
                        class="form-control" formControlName="idClassification">
                          <option hidden selected value="" >Seleccione Clasificación</option>
                          <option [value]="classification.idClassification" *ngFor="let classification of Classifications">{{classification.ClassificationName}} ({{classification.QuestionCount}})</option>
                      </select>
                      <div class="invalid-feedback" >Por favor seleccione una clasificación</div>   
                    </div>
                  <button class="btn btn-danger ml-3 " type="button" (click)="removeClassification(item.key)"><i class="fa fa-minus"></i></button>
                 </div>
              </div>  
            </div>
          </div>
        </div>

        <h5>Tiempo por pregunta</h5>
        <div class="form-group">
          <input [(ngModel)]="exam.QuestionTime" type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': updateForm.get('QuestionTime').errors && updateForm.get('QuestionTime').touched }"
          class="form-control" formControlName="QuestionTime" placeholder="Tiempo para por pregunta (Segundos)">
          <div class="invalid-feedback">Porfavor asigne un tiempo para pasar a la siguiente pregunta</div>
        </div>
        
        <h5>Número de preguntas</h5>
        <div class="form-group">
          <input [(ngModel)]="exam.QuestionNumber" type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': updateForm.get('QuestionNumber').errors && updateForm.get('QuestionNumber').touched }"
          class="form-control" formControlName="QuestionNumber" placeholder="Número de preguntas">
          <div class="invalid-feedback">Agregue un número de pregunta para el examen</div>
        </div>

        <h5>Precio</h5>
        <div class="form-group">
          <input [(ngModel)]="exam.Price" type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': updateForm.get('Price').errors && updateForm.get('Price').touched }"
          class="form-control" formControlName="Price" placeholder="Precio del examen">
          <div class="invalid-feedback">Porfavor asigne un precio al examen</div>
        </div>
        
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input [(ngModel)]="exam.Active" formControlName="Active" type="checkbox" class="custom-control-input" id="customSwitches">
            <label class="custom-control-label" for="customSwitches">Activo</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input [(ngModel)]="exam.SendQualification" formControlName="SendQualification" type="checkbox" class="custom-control-input" id="SendQualification">
            <label class="custom-control-label" for="SendQualification">Enviar calificación a estudiante</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input [(ngModel)]="exam.RespectQuestionOrder" formControlName="RespectQuestionOrder" type="checkbox" class="custom-control-input" id="RespectQuestionOrder">
            <label class="custom-control-label" for="RespectQuestionOrder">Respetar orden de preguntas</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input (change)="onChangeHasReading()" [(ngModel)]="exam.HasReading" formControlName="HasReading" type="checkbox" class="custom-control-input" id="HasReading">
            <label class="custom-control-label" for="HasReading">Tiene Lectura?</label>
          </div>
        </div>

        <h5 *ngIf="ShowReadings">Lectura</h5>
        <div *ngIf="ShowReadings" class="form-group">
          <select [(ngModel)]="exam.idReading" class="custom-select" [ngClass]="{'is-invalid': updateForm.get('idReading').errors && updateForm.get('idReading').touched }"
          class="form-control" formControlName="idReading">
          <option hidden selected value="" >Seleccione una Lectura</option>
          <option [value]="reading.idReading" *ngFor="let reading of Readings">{{reading.Name}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione una clasificación</div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!updateForm.dirty || !updateForm.valid" type="submit">Actualizar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/exams']">Cancelar</button>
        </div>
      </form>
    </div>
  </div>
</div>
