import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Level } from 'src/app/_models/Level';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-level-add',
  templateUrl: './level-add.component.html',
  styleUrls: ['./level-add.component.css']
})
export class LevelAddComponent implements OnInit {
  registerForm: FormGroup;
  Level: Level;

  constructor(private router: Router, private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      LevelName: ['', Validators.required],
      LevelDescription: ['', Validators.required]
    });
  }

  registerLevel(){
    if (this.registerForm.valid){
      this.Level = Object.assign({}, this.registerForm.value);
      this.Level.Active = true;
      this.examService.insertLevel(this.Level).subscribe(() => {
          this.alertify.success('Nivel Agregado');
          this.router.navigate(['/levels']);
        }, error => {
          this.alertify.error(error);
        }, () => {
           // this.authService.login(this.user);
        });
    }
    // console.log(this.registerForm.value);
  }
}
