<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="registerForm" (ngSubmit)="registerClassification()">
        <h2 class="text-center text-primary">Registrar Clasificación</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" 
          [ngClass]="{'is-invalid': registerForm.get('ClassificationName').errors && registerForm.get('ClassificationName').touched }"
          class="form-control" formControlName="ClassificationName" placeholder="Nombre de clasificación">
          <div class="invalid-feedback">Porfavor ingrese el nombre de la calisificación</div>
        </div>

        <div class="form-group">
          <textarea class="form-control"  rows="3" [ngClass]="{'is-invalid': registerForm.get('ClassificationDescription').errors && registerForm.get('ClassificationDescription').touched }"
          class="form-control" formControlName="ClassificationDescription" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group">
          <select class="custom-select" [ngClass]="{'is-invalid': registerForm.get('idLevel').errors && registerForm.get('idLevel').touched }"
          class="form-control" formControlName="idLevel" placeholder="Nivel de la clasificación">
          <option hidden selected value="" >Selecione un nivel</option>
          <option [value]="level.idLevel" *ngFor="let level of Levels">{{level.LevelName}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un nivel</div>
        </div>

        <div class="form-group">
          <input type="text"  (keypress)="onlyNumberKey($event)" maxlength="2"
          [ngClass]="{'is-invalid': registerForm.get('ClassificationOrder').errors && registerForm.get('ClassificationOrder').touched }"
          class="form-control" formControlName="ClassificationOrder" placeholder="Orden de la clasificación">
          <div class="invalid-feedback">Porfavor agregue el orden de la clasificación</div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Register</button>
          <button class="btn btn-default" type="button" [routerLink]="['/classifications']">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
