import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CourseInsertUpdate } from 'src/app/_models/Request/CourseInsertUpdate';
import { UserCourseInsertRequest } from 'src/app/_models/Request/UserCourseInsertRequest';
import { Course } from 'src/app/_models/Course';
import { CourseTypes } from 'src/app/_models/CourseTypes';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  baseUrl = environment.apiUrl;
constructor(private http: HttpClient) { }

  //inserts
  insertCourse(course: CourseInsertUpdate) {
    return this.http.post(this.baseUrl + 'Course/InsertCourse', course);
  }

  insertUserCourse(course: UserCourseInsertRequest) {
    return this.http.post(this.baseUrl + 'Course/InsertUserCourse', course);
  }

  //gets
  getCourse(id: number): Observable<Course>{
    return this.http.get<Course>(this.baseUrl + 'Course/GetCourse/' + id);
  }

  GetCoursesByUser(id: number): Observable<Course[]>{
    return this.http.get<Course[]>(this.baseUrl + 'Course/GetCoursesByUser/' + id);
  }

  GetAllCourses(type: number): Observable<Course[]>{
    return this.http.get<Course[]>(this.baseUrl + 'Course/GetAllCourses/' + type);
  }

  GetCourseTypes(): Observable<CourseTypes[]>{
    return this.http.get<CourseTypes[]>(this.baseUrl + 'Course/GetCourseTypes');
  }

  //updates
  UpdateCourse(course: CourseInsertUpdate): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Course/UpdateCourse', course);
  }

}
