<form [formGroup]="registerForm" (ngSubmit)="register()">
  <h2 class="text-center text-primary">Registrarse</h2>
  <hr>

  <div class="form-group">
    <label class="control-label" style="margin-right:10px">Género: </label>
    <label class="radio-inline">
      <input class="mr-3" type="radio" value="H" formControlName="Gender">Hombre
    </label>
    <label class="radio-inline ml-3">
      <input class="mr-3" type="radio" value="M" formControlName="Gender">Mujer
    </label>
  </div>
  
  <div class="form-group">
    <input type="text" 
    [ngClass]="{'is-invalid': registerForm.get('Name').errors && registerForm.get('Name').touched }"
    class="form-control" formControlName="Name" placeholder="Nombre(s)">
    <div class="invalid-feedback">Porfavor ingrese su(s) nombre(s)</div>
  </div>

  <div class="form-group">
    <input type="text" 
    [ngClass]="{'is-invalid': registerForm.get('LastName').errors && registerForm.get('LastName').touched }"
    class="form-control" formControlName="LastName" placeholder="Apellido(s)">
    <div class="invalid-feedback">Porfavor ingrese su(s) apellido(s) </div>
  </div>

  <div class="form-group">
    <input type="text" 
    [ngClass]="{'is-invalid': registerForm.get('UserName').errors && registerForm.get('UserName').touched
    || registerForm.get('UserName').touched && registerForm.hasError('userInvalid') }"
    class="form-control" formControlName="UserName" placeholder="Nombre de usuario">
    <div *ngIf="registerForm.get('UserName').errors && registerForm.get('UserName').touched" 
      class="invalid-feedback">
      Porfavor elija un nombre de usuario
    </div>
    <div *ngIf="registerForm.hasError('userInvalid') && registerForm.get('UserName').touched"
      class="invalid-feedback">
      El nombre de usuario contiene caracteres especiales, evite usar caracteres tales como ", . - ' ? ñ o acentos"
    </div>
  </div>

  <div class="form-group">
    <input type="password" 
    [ngClass]="{'is-invalid': registerForm.get('Password').errors && registerForm.get('Password').touched }"
    class="form-control" formControlName="Password" placeholder="Contraseña">
    <div *ngIf="registerForm.get('Password').hasError('required') && registerForm.get('Password').touched"
      class="invalid-feedback">
      Debe ingresar una contraseña
    </div>
    <div *ngIf="registerForm.get('Password').hasError('minlength') && registerForm.get('Password').touched"
      class="invalid-feedback">
      La contraseña debe ser de minimo 6 caracteres
    </div>
    <div *ngIf="registerForm.get('Password').hasError('maxlength') && registerForm.get('Password').touched"
      class="invalid-feedback">
      La contraseña no debe exceder los 32 caracteres
    </div>
  </div>

  <div class="form-group">
    <input type="password" 
    [ngClass]="{'is-invalid': registerForm.get('ConfirmPassword').errors && registerForm.get('ConfirmPassword').touched
    || registerForm.get('ConfirmPassword').touched && registerForm.hasError('passDif')}"
    class="form-control" formControlName="ConfirmPassword" placeholder="Confirmar contraseña">
    <div *ngIf="registerForm.get('ConfirmPassword').hasError('required') && registerForm.get('ConfirmPassword').touched"
      class="invalid-feedback">
      Debe ingresar una contraseña
    </div>
    <div *ngIf="registerForm.hasError('passDif') && registerForm.get('ConfirmPassword').touched"
      class="invalid-feedback">
      La contraseña debe ser igual
    </div>
  </div>

  <div class="form-group">
    <input type="email" 
    [ngClass]="{'is-invalid': registerForm.get('Email').errors && registerForm.get('Email').touched}"
    class="form-control" formControlName="Email" placeholder="Email">
    <div *ngIf="registerForm.get('Email').hasError('required') && registerForm.get('Email').touched"
      class="invalid-feedback">
      Debe ingresar un email
    </div>
  </div>

  <div class="form-group">
    <input type="email" 
    [ngClass]="{'is-invalid': registerForm.get('ConfirmEmail').errors && registerForm.get('ConfirmEmail').touched
    || registerForm.get('ConfirmEmail').touched && registerForm.hasError('emailDif')}"
    class="form-control" formControlName="ConfirmEmail" placeholder="Confirmar email">
    <div *ngIf="registerForm.get('ConfirmEmail').hasError('required') && registerForm.get('ConfirmEmail').touched"
      class="invalid-feedback">
      Debe ingresar un email
    </div>
    <div *ngIf="registerForm.hasError('emailDif') && registerForm.get('ConfirmEmail').touched"
      class="invalid-feedback">
      El correo debe ser igual
    </div>
  </div>

  <div class="form-group">
    <input type="text" 
    [ngClass]="{'is-invalid': registerForm.get('Profession').errors && registerForm.get('Profession').touched }"
    class="form-control" formControlName="Profession" placeholder="Profesión">
    <div class="invalid-feedback">Porfavor ingrese su profesión</div>
  </div>

  <div class="form-group">
    <input [ngClass]="{'is-invalid': registerForm.get('DateOfBirth').errors && registerForm.get('DateOfBirth').touched}" class="form-control"
      placeholder="Fecha de nacimiento" formControlName="DateOfBirth" type="text" bsDatepicker [bsConfig]="bsConfig" >
    <div class="invalid-feedback" *ngIf="registerForm.get('DateOfBirth').touched && registerForm.get('DateOfBirth').hasError('required')">Porfavor ingrese su fecha de nacimiento</div>
  </div>

  <div class="form-group">
    <input [ngClass]="{'is-invalid': registerForm.get('Country').errors && registerForm.get('Country').touched}" class="form-control"
      placeholder="País" formControlName="Country">
    <div class="invalid-feedback" *ngIf="registerForm.get('Country').touched && registerForm.get('Country').hasError('required')">porfavro de igresar su país</div>
  </div>

  <div class="form-group">
    <input [ngClass]="{'is-invalid': registerForm.get('City').errors && registerForm.get('City').touched}" class="form-control"
      placeholder="Ciudad" formControlName="City">
    <div class="invalid-feedback" *ngIf="registerForm.get('City').touched && registerForm.get('City').hasError('required')">porfavor ingrese su ciudad</div>
  </div>

  <div class="form-group">
    <input type="text" 
    [ngClass]="{'is-invalid': registerForm.get('AcademicDegree').errors && registerForm.get('AcademicDegree').touched }"
    class="form-control" formControlName="AcademicDegree" placeholder="Grado académico">
    <div class="invalid-feedback">Porfavor ingrese su grado académico</div>
  </div>
  
  <div class="form-group">
    <input type="text" 
    [ngClass]="{'is-invalid': registerForm.get('Subject').errors && registerForm.get('Subject').touched }"
    class="form-control" formControlName="Subject" placeholder="Temas de interés">
    <div class="invalid-feedback">Porfavor ingrese temas de interés</div>
  </div>

  <div class="form-group text-center">
    <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Register</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>

</form>
