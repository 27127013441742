<div class="container mt-4">
      <div class="col sm-7">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
              <th scope="col" class="p-1 align-middle text-center">Nombre</th>
              <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Duración</th>
              <th scope="col" class="p-1 align-middle text-center">Modalidad</th>
              <th scope="col" class="p-1 align-middle text-center">Tipo</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let course of Courses" class="p-0">
              <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{course.idCourses}}</th>
              <td class="p-0 align-middle">{{course.Name}}</td>
              <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{course.Duration}}</td>
              <td class="p-0 align-middle text-center">{{course.Modality}}</td>
              <td class="p-0 align-middle text-center">{{course.TypeName}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
  