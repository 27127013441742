<div class="text-center mt-5">
  <div class="center_div">
    <div class="container mt-4 text-left">
      <tabset class="member-tabset">
        <tab heading='Información de Lectura'>
          <h4>Nombre</h4>
          <p>{{reading.Name}}</p>
          <h4>Descripción</h4>
          <p>{{reading.Description}}</p>
          <h4>Tiempo de lectura</h4>
          <p>{{reading.ReadingTime}}</p>
        </tab>
        <tab heading='Lectura'>
          <h4>Lectura</h4>
          <p>{{reading.Reading}}</p>
        </tab>
      </tabset>
    </div>
    <div class="form-group text-center">
      <button class="btn btn-success" type="button" [routerLink]="['/readings']">Regresar</button>
    </div>
  </div>
</div>