import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Level } from 'src/app/_models/Level';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-level-edit',
  templateUrl: './level-edit.component.html',
  styleUrls: ['./level-edit.component.scss']
})
export class LevelEditComponent implements OnInit {
  updateForm: FormGroup;
  Level: Level;
  constructor(private router: Router, private examService: ExamService, private route: ActivatedRoute, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.Level = data.level;
    });

    this.createUpdateForm();
  }

  createUpdateForm() {
    this.updateForm = this.fb.group({
      Active: [this.Level.Active, Validators.required],
      LevelName: [this.Level.LevelName, Validators.required],
      LevelDescription: [this.Level.LevelDescription, Validators.required]
    });
  }

  updateLevel(){
    this.examService.UpdateLevel(this.Level).subscribe(() => {
      this.alertify.success('Nivel Actualizado');
      this.router.navigate(['/levels']);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
       //this.authService.login(this.user);
    });
    //this.examService.UpdateExam(this.updateForm.value);
    this.updateForm.reset(this.updateForm.value);
    console.log(this.updateForm.value);
  }
}
