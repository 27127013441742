import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { Exam } from 'src/app/_models/Exam';
import { ExamClassification } from 'src/app/_models/ExamClassification';
import { Level } from 'src/app/_models/Level';
import { ReadingModel } from 'src/app/_models/ReadingModel';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-exam-detail',
  templateUrl: './exam-detail.component.html',
  styleUrls: ['./exam-detail.component.scss']
})
export class ExamDetailComponent implements OnInit {
  Exam: Exam;
  Level: Level = { Active: true, LevelDescription: '', idLevel: 0, LevelName: '' };
  Classifications: Classification[] = [];
  Reading: ReadingModel = { idReading: 0, Reading: '', Description: '', ReadingTime: 0, Name: ''};

  constructor(private route: ActivatedRoute, private examService: ExamService, private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.Exam = data.exam;

      this.getLevelInfo(this.Exam.IdLevel);
      
      this.Exam.Classifications.forEach(element => {
        this.getClassificationInfo(element.idClassification);  
      });
      
      if (this.Exam.HasReading) {
        this.getReading(this.Exam.idReading);
      }
    });
  }

  getLevelInfo(idLevel: number){
    this.examService.getLevel(idLevel).subscribe((level: Level) => {
      this.Level = level;
    }, error => {
      this.alertify.error(error);
    });
  }

  getClassificationInfo(idClassification: number){
    this.examService.getClassification(idClassification).subscribe((classification: Classification) => {
      this.Classifications.push(classification);
    }, error => {
      this.alertify.error(error);
    });
  }

  getReading(idReading: number) {
    this.examService.getReading(idReading).subscribe((reading: ReadingModel) => {
      this.Reading = reading;
    }, error => {
      this.alertify.error(error);
    });
  }
}
