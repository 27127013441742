<form [formGroup]="registerForm">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" class="p-1 align-middle text-center w-25">Nivel</th>
        <th scope="col" class="p-1 align-middle text-center w-25">Clasificación</th>
        <th scope="col" class="p-1 align-middle text-center w-25">Examen</th>
        <th scope="col" class="p-1 align-middle text-center w-25">Fecha Límite</th>
      </tr>
    </thead>
    <tr>
      <td class="p-1 align-middle text-center">
        <div class="form-group">
          <select class="custom-select" id="Level" (change)="onChangeLevel($event.target.value)"
          class="form-control">
          <option hidden [value]="0" >Seleccionar</option>
          <option [value]="level.idLevel" *ngFor="let level of levels">{{level.LevelName}}</option>
          </select>
        </div>
      </td>
      <td class="p-1 align-middle text-center">
        <div class="form-group">
          <select class="custom-select" id="Classification" (change)="onChangeClassification($event.target.value)"
           class="form-control">
           <option hidden [value]="0" >Seleccionar</option>
            <option [value]="classification.idClassification" 
                    *ngFor="let classification of Classifications">
                    {{classification.ClassificationName}}
            </option>
          </select>
        </div>
      </td>
      <td class="p-1 align-middle text-center">
        <div class="form-group">
          <select id="ExamSelect" [ngClass]="{'is-invalid': registerForm.get('idExam').errors && registerForm.get('idExam').touched }" formControlName="idExam" class="custom-select" class="form-control">
            <option hidden selected value="" >Seleccionar</option>
            <option [value]="exam.idExams" 
                    *ngFor="let exam of exams">
                    {{exam.Name}}
            </option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un Examen</div>
        </div>
      </td>
      <td class="p-2 align-middle text-center">
        <div class="form-group">
          <input [ngClass]="{'is-invalid': registerForm.get('ExpirationDate').errors && registerForm.get('ExpirationDate').touched
          || registerForm.get('ExpirationDate').touched && registerForm.hasError('dateGreater') }" class="form-control"
            placeholder="Fecha de vencimiento" formControlName="ExpirationDate" type="text" bsDatepicker [bsConfig]="bsConfig" placement="left" >
          <div class="invalid-feedback" *ngIf="registerForm.get('ExpirationDate').touched && registerForm.get('ExpirationDate').hasError('required')">
            Se requiere la fecha de expiracion
          </div>
          <div *ngIf="registerForm.hasError('dateGreater') && registerForm.get('ExpirationDate').touched" class="invalid-feedback">
            La fecha de vencimiento debe ser mayor a hoy
          </div>
        </div>
      </td>
      <td class="p-2 align-middle text-center d-none d-lg-table-cell">
        <div class="form-group">
          <button class="btn btn-outline-primary w-100 pr-0 pl-0" style="min-width: 100px;" (click)="assingExam()" [disabled]="!registerForm.valid" type="submit" >Asignar <i class="fa fa-pencil-square-o"></i></button>
        </div>
      </td>
    </tr>
    <tr class="d-lg-none">
      <td colspan="4">
        <div class="form-group">
          <button class="btn btn-outline-primary w-100 pr-0 pl-0" (click)="assingExam()" [disabled]="!registerForm.valid" type="submit" >Asignar <i class="fa fa-pencil-square-o"></i></button>
        </div>
      </td>
      
    </tr>
  </table>
  
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
        <th scope="col" class="p-1 align-middle text-center">Nombre</th>
        <th scope="col" class="p-1 align-middle text-center">Puntaje</th>
        <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Descripción</th>
        <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Fecha de asignación</th>
        <th scope="col" class="p-1 align-middle text-center">Fecha de vencimiento</th>
      </tr>
    </thead>
    
    <tr *ngFor="let userexam of userExams; let i = index" class="p-0">
      <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{i+1}}</th>
      <td class="p-0 align-middle text-center">{{userexam.ExamName}}</td>
      <td class="p-0 align-middle text-center">{{userexam.Score}}</td>
      <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{userexam.ExamDescription}}</td>
      <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{userexam.CreationDate | date:"MM/dd/yyy"}}</td>
      <td class="p-0 align-middle text-center">{{userexam.ExpirationDate | date:"MM/dd/yyy"}}</td>
    </tr>
  </table>
</form>