import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/_models/Course';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CourseService } from 'src/app/_services/course.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-list-courses',
  templateUrl: './list-courses.component.html',
  styleUrls: ['./list-courses.component.scss']
})
export class ListCoursesComponent implements OnInit {
  Courses: Course[];
  userCourses: Course[];
  hideActions: boolean;
  paymentMode: boolean;
  selectedCourseId: number;

  constructor(private route: ActivatedRoute, public authService: AuthService, 
              private userService: UserService, private alertify: AlertifyService,
              private courseService: CourseService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.Courses = data.Courses;
    });
    this.getUserCourses()
    this.hasRol();
  }

  hasRol() {
    this.userService.hasRol(this.getUserId()).subscribe((hide: boolean) => {
      this.hideActions = hide;
    });
  }

  getUserCourses() {
    this.courseService.GetCoursesByUser(this.getUserId()).subscribe((courses: Course[]) => {
      this.userCourses = courses;
    }, error => {
      this.alertify.error(error);
    });
  }

  getUserId(): number {
    return this.authService.getUserId();
  }

  onChangeActiveFilter(value){
    this.courseService.GetAllCourses(value).subscribe((courses: Course[]) => {
      this.Courses = courses;
    }, error => {
      this.alertify.error(error);
    });
  }

  paymentToggle(idCourse: number) {
    let hasCourse = this.userCourses.find(x => x.idCourses == idCourse);
    if (hasCourse != null) {
      this.alertify.warning('Ya cuentas con este curso');
    }
    else
    {
      this.selectedCourseId = idCourse;
      this.paymentMode = true;
    }
  }

  cancelPaymentMode(paymentMode: boolean) {
    this.paymentMode = paymentMode;
  }
}
