
<div class="text-center mt-3">
  <h3>Actulizar Pregunta</h3>  
  <div class="center_div mt-3">

    <div class="container">
      <form #editForm="ngForm" id="editForm" (ngSubmit)="updateQuestion()">
        <h4>Pregunta</h4>
        <input type="text" class="form-control mt-2" name="Question" [(ngModel)]="question.Question">

        <h4 class="mt-3">Activo</h4>
        <div class="form-group ">
          <div class="custom-control custom-switch">
            <input [(ngModel)]="question.Active" name="Active" type="checkbox" class="custom-control-input" id="Active">
            <label class="custom-control-label" for="Active"></label>
          </div>
        </div>

        <h4>Número de opciones</h4>
        <input type="text" class="form-control mt-3" name="OptionsNumber" disabled [(ngModel)]="question.OptionsNumber">

        <h3 class="mt-4">Respuestas</h3>
        <div class="row text-center mt-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style="width: 10px;" class="p-1 align-middle text-center">id</th>
                <th scope="col" class="p-1 align-middle text-center">Respuesta</th>
                <th scope="col" style="width: 100px;" class="p-1 align-middle text-center">Correcta</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of anwers; let i = index">
                <td>{{i+1}}</td>
                <td><input type="text" class="form-control" [name]="('Answer'+i)" [(ngModel)]="anwers[i].Answer"></td>
                <td>
                    <div class="custom-control custom-switch">
                      <input [(ngModel)]="anwers[i].IsCorrect" [name]="('IsCorrect'+i)" type="checkbox" class="custom-control-input" [id]="('IsCorrect'+i)">
                      <label class="custom-control-label" [for]="('IsCorrect'+i)"></label>
                    </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div class="card" style="width: 100px;"> 
                    <button (click)="addAnswer()" type="button" class="btn btn-info btn-block">Agregar</button>
                  </div>
                </td>
              </tr>
            </tbody>
            
          </table>
        </div>
        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!editForm.dirty || !editForm.valid" form="editForm">Actualizar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/classifications/edit/questions/' + question.idClassification]">Cancelar</button>
        </div>
      </form>
    </div>
  </div>
</div>
