<div class="text-center mt-5">
  <div class="center_div">
    <div class="container mt-4 text-left">
      <tabset class="member-tabset">
        <tab heading='Información del Curso'>
          <h4>Nombre</h4>
          <p>{{course.Name}}</p>
          <h4>Precio</h4>
          <p>{{course.Price}}</p>
          <h4>Duración</h4>
          <p>{{course.Duration}}</p>
          <h4>Modalidad</h4>
          <p>{{course.Modality}}</p>
          <h4>Tipo</h4>
          <p>{{course.TypeName}}: {{course.TypeDescription}}</p>
          <h4>Activo</h4>
          <p>{{course.Active?'Si':'No'}}</p>
          <h4>Exámenes</h4>
          <p *ngFor="let exam of course.CourseExams" >{{exam.examName}}</p>
        </tab>
        <tab heading='Descripción'>
          <h4>Descripción</h4>
          <p>{{course.Description}}</p>
        </tab>
      </tabset>
    </div>
    <div class="form-group text-center">
      <button class="btn btn-success" type="button" [routerLink]="['/courses']">Regresar</button>
    </div>
  </div>
</div>