<div  class="container-fluid w-50">
  <h1>{{this.examToPresent.Exam.Name}}</h1>
  <ngb-carousel (slide)="this.onSlide()"  #myCarousel="ngbCarousel">
    <ng-template *ngIf="showReading" ngbSlide id="{{0}}" >
      <div class="wrapper">
        <div class="slideContainer">
          <div class="d-flex flex-row-reverse mr-3 ml-3 mt-3" style="min-width: 20px;"> 
            <h4>{{counter}}</h4> 
          </div>
          <p style="text-align: justify;" class="lead ml-3 mr-3 mt-3" >{{reading.Reading}}</p> 
          <div>
            <button type="button" (click)="nextSlide()"class="btn btn-secondary mt-3 mb-3" style="min-width: 120px;">
              Continuar
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide *ngFor="let question of Questions; let i = index" id="{{i + 1}}">
      <div class="wrapper">
        <div class="slideContainer">
          <div class="d-flex justify-content-between">
            <div class="ml-4 mr-3 mt-3"> <h4>{{i+1}}.</h4>  </div>
            <div class="mt-3"> <h3>{{question.Question.Question}}</h3> </div>
            <div class="mr-3 ml-3 mt-3" style="min-width: 20px;"> <h4>{{counter}}</h4> </div>
          </div>
          <div>
            <div class="btn-group-vertical mt-4" role="group" >
              <button *ngFor="let answer of question.Answers" 
                      type="button" (click)="addAnswer(question.Question.idQuestion, answer.idAnswer)"
                      class="btn btn-secondary mt-3" style="min-width: 120px;">{{answer.Answer}}</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide id="{{this.Questions.length + 1}}" >
      <div class="wrapper">
        <div class="slideContainer" >
          <p class="lead ml-4 mr-3 mt-5" >Se han enviado tus respuestas. Recibirás un E-Mail con la calificación, en el correo registrado ó puedes regresar
            a la pestaña de "My Exams" para ver la calificación.</p> 
            <div>
              <button type="button" [routerLink]="['/myexams']" routerLinkActive="router-link-active" 
                      class="btn btn-secondary mt-4" style="min-width: 120px;">Regresar</button>
            </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
