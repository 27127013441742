<div class="container mt-5">
  <div class="row d-flex justify-content-center">
    <div>
      <ngx-paypal *ngIf="showPaypalButtons" [config]="payPalConfig"></ngx-paypal>
    </div>
    
  </div>
  <!-- <button *ngIf="!showPaypalButtons" 
  (click)="pay()">Pagar con paypal</button> -->

  <!-- <button  *ngIf="showPaypalButtons" (click)="back()">Volver atras</button> -->
  <div class="form-group text-center">
    <button class="btn btn-danger w-100" type="button" (click)="cancel()">Cancel</button>
  </div>
</div>



