import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { UserExams } from 'src/app/_models/UserExams';

@Component({
  selector: 'app-list-userExams',
  templateUrl: './list-userExams.component.html',
  styleUrls: ['./list-userExams.component.scss']
})
export class ListUserExamsComponent implements OnInit {

  PresentExamMode: boolean = false;
  ExamSelected: number;
  idUserExamSelected: number;
  exams: UserExams[];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.exams = data.userExams;
    });
  }

  examToPresentDatailToggle(idExam: number, idUserExamSelected: number) {
    this.idUserExamSelected = idUserExamSelected;
    this.ExamSelected = idExam;
    this.PresentExamMode = true;
  }

  cancelExamToPresentDatail(PresentExamMode: boolean) {
    this.PresentExamMode = PresentExamMode;
  }

  validateExpirationDateDate(expirationDate: Date): boolean {
    if (moment(expirationDate) < moment())
    {
      return true;
    }
    return false;
  }
}
