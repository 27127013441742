import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { Course } from 'src/app/_models/Course';
import { CourseExam } from 'src/app/_models/CourseExam';
import { CourseTypes } from 'src/app/_models/CourseTypes';
import { Exam } from 'src/app/_models/Exam';
import { Level } from 'src/app/_models/Level';
import { CourseInsertUpdate } from 'src/app/_models/Request/CourseInsertUpdate';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { CourseService } from 'src/app/_services/course.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-course-add',
  templateUrl: './course-add.component.html',
  styleUrls: ['./course-add.component.scss']
})
export class CourseAddComponent implements OnInit {
  courseTypes: CourseTypes[];
  courseExams: CourseExam[];
  exams: Exam[];
  levels: Level[];
  Classifications: Classification[];
  registerForm: FormGroup;
  course: CourseInsertUpdate;

  constructor(private courseService: CourseService, private examService: ExamService, private alertify: AlertifyService,
              private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.loadLevels();
    this.createRegisterForm();
    this.getCourseTypes();
  }

  getCourseTypes() {
    this.courseService.GetCourseTypes().subscribe((courseTypes: CourseTypes[]) => {
      this.courseTypes = courseTypes;
    }, error => {
      this.alertify.error(error);
    });
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      Name: ['', Validators.required],
      Description: ['', Validators.required],
      Price: ['', Validators.required],
      Duration: ['', Validators.required],
      Modality: ['', Validators.required],
      CourseType: ['', Validators.required],
      CourseExams: this.fb.array([])
    });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  createCourseExamsFrom(idExam:number, examName:string): FormGroup{
    return new FormGroup ({
      idCourse: new FormControl(0),
      idExam: new FormControl(idExam, Validators.required),
      examName: new FormControl(examName)
    });
  }

  addExam(){
    const CourseExams = this.registerForm.get('CourseExams') as FormArray;
    let idExam:number = +(<HTMLSelectElement>document.getElementById('ExamSelect')).value;
    if (idExam <= 0) {
      this.alertify.error('Favor de selecionar un examen');
      return;
    }
    else {
      let examName: string = this.exams[+(<HTMLSelectElement>document.getElementById('ExamSelect')).selectedIndex -1].Name;
      let elementExist = this.examAlredyAdded(idExam, examName);
      if(!elementExist) {
        CourseExams.push(this.createCourseExamsFrom(idExam, examName));
      }
    }
  }

  removeExam(id: number) {
    const CourseExams = this.registerForm.get('CourseExams') as FormArray;
    CourseExams.removeAt(id);
  }

  public getFormArray(): FormArray{
    var form = this.registerForm.get('CourseExams') as FormArray;
    return form;
  }
  
  examAlredyAdded(idExam: number, examName: string): boolean{
    let exist: boolean = false;
    this.getFormArray().controls.forEach(element => {
      if (element.get('idExam').value === idExam) {
        this.alertify.error('El examen ' + examName + ' ya se agrego');
        exist = true;
      }
    });
    return exist;
  }

  registerCourse(){
    if (this.registerForm.valid){
        this.course = Object.assign({}, this.registerForm.value);
        this.course.Active = true;
        this.courseService.insertCourse(this.course).subscribe(() => {
          this.alertify.success('Examen Agregado');
          this.router.navigate(['/exams']);
        }, error => {
          this.alertify.error(error);
        }, () => {
           //this.authService.login(this.user);
        });
    }
  }

  // carga de datos
  loadLevels() {
    this.examService.getLevels().subscribe((levels: Level[]) => {
      this.levels = levels;
    }, error => {
      this.alertify.error(error);
    });
  }

  onChangeLevel(value){
    // cargamos las classificaciones
    this.examService.getClassifications(value).subscribe((classifications: Classification[]) => {
      this.Classifications = classifications;
    }, error => {
      this.alertify.error(error);
    });
  }

  onChangeClassification(value){
    let idLevel: number = +(<HTMLSelectElement>document.getElementById('Level')).value;
    // cargamos los examenes
    this.examService.GetExamsByLvlAndClas(idLevel, value).subscribe((exam: Exam[]) => {
      this.exams = exam;
    }, error => {
      this.alertify.error(error);
    });
  }
}
