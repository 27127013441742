import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Course } from 'src/app/_models/Course';
import { UserCourseInsertRequest } from 'src/app/_models/Request/UserCourseInsertRequest';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { CourseService } from 'src/app/_services/course.service';

@Component({
  selector: 'app-course-assign',
  templateUrl: './course-assign.component.html',
  styleUrls: ['./course-assign.component.css']
})
export class CourseAssignComponent implements OnInit {
  
  constructor(private router: Router, private fb: FormBuilder, 
              private alertify: AlertifyService, private courseService: CourseService) { }
  
  @Input() idUser: number;
  
  registerForm: FormGroup;
  userCourseRequest: UserCourseInsertRequest;
  courses: Course[];
  userCourses: Course[];
  
  ngOnInit() {
    this.loadCourses();
    this.loadAssignedCourses();
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      idUser: [this.idUser],
      idCourse: ['', Validators.required]
    });
  }

  loadCourses() {
    this.courseService.GetAllCourses(1).subscribe((courses: Course[]) => {
      this.courses = courses;
    }, error => {
      this.alertify.error(error);
    });
  }

  loadAssignedCourses(){
    this.courseService.GetCoursesByUser(this.idUser).subscribe((userCourses: Course[]) => {
      this.userCourses = userCourses;
    }, error => {
      this.alertify.error(error);
    });
  }

  courseAssing() {
    let idExam:number = +(<HTMLSelectElement>document.getElementById('CourseSelect')).value;
    if (idExam <= 0){
      this.alertify.error('Favor de selecionar un examen');
      return;
    }

    this.userCourseRequest = this.registerForm.value;
    console.log(this.userCourseRequest);
    this.courseService.insertUserCourse(this.userCourseRequest).subscribe(() => {
      this.alertify.success('Curso Asignado');
      this.router.navigateByUrl('', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/users/detail/' + this.idUser]);
      });
    }, error => {
      this.alertify.error(error);
    }, () => {
       // this.authService.login(this.user);
    });
  }

}
