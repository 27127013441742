<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="updateForm" (ngSubmit)="updateClassification()">
        <h2 class="text-center text-primary">Actualizar Clasificación</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" [(ngModel)]="classification.ClassificationName"
          [ngClass]="{'is-invalid': updateForm.get('ClassificationName').errors && updateForm.get('ClassificationName').touched }"
          class="form-control" formControlName="ClassificationName" placeholder="Nombre de clasificación">
          <div class="invalid-feedback">Porfavor ingrese el nombre de la calisificación</div>
        </div>

        <div class="form-group">
          <textarea [(ngModel)]="classification.ClassificationDescription" class="form-control"  rows="3" [ngClass]="{'is-invalid': updateForm.get('ClassificationDescription').errors && updateForm.get('ClassificationDescription').touched }"
          class="form-control" formControlName="ClassificationDescription" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group">
          <select [(ngModel)]="classification.idLevel" class="custom-select" [ngClass]="{'is-invalid': updateForm.get('idLevel').errors && updateForm.get('idLevel').touched }"
          class="form-control" formControlName="idLevel" placeholder="Nivel del examen">
          <option [value]="level.idLevel" *ngFor="let level of Levels">{{level.LevelName}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un nivel</div>
        </div>

        <div class="form-group">
          <input [(ngModel)]="classification.ClassificationOrder" type="text"  (keypress)="onlyNumberKey($event)" maxlength="2"
          [ngClass]="{'is-invalid': updateForm.get('ClassificationOrder').errors && updateForm.get('ClassificationOrder').touched }"
          class="form-control" formControlName="ClassificationOrder" placeholder="Ordern de la clasificación">
          <div class="invalid-feedback">Porfavor ingrese el orden</div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input [(ngModel)]="classification.Active" formControlName="Active" type="checkbox" class="custom-control-input" id="customSwitches">
            <label class="custom-control-label" for="customSwitches">Activo</label>
          </div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!updateForm.dirty || !updateForm.valid" type="submit">Actualizar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/classifications']">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
