import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

import { AppComponent } from './app.component';
import { LoginMobileComponent } from './Components/login-mobile/login-mobile.component';
import { ExamsComponent } from './Exams/Exams.component';
import { ListExamsResolver } from './_resolvers/list-exams.resolver';
import { ExamRowComponent } from './Components/exam-row/exam-row.component';
import { ExamEditComponent } from './Components/exam-edit/exam-edit.component';
import { ExamDetailResolver } from './_resolvers/exam-detail.resolver';
import { ExamDetailComponent } from './Components/exam-detail/exam-detail.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { AlertifyService } from './_services/alertify.service';
import { UserService } from './_services/user.service';
import { HomeComponent } from './Components/home/home.component';
import { RegisterComponent } from './Components/register/register.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { appRoutes } from './routes';
import { UserEditComponent } from './Components/User-edit/User-edit.component';
import { UserEditResolver } from './_resolvers/user-edit.resolver';
import { UserDetailComponent } from './Components/user-detail/user-detail.component';
import { UserDetailResolver } from './_resolvers/user-detail.resolver';
import { ListUsersComponent } from './Components/list-users/list-users.component';
import { ListUsersResolver } from './_resolvers/list-users.resolver';
import { UserRowComponent } from './Components/user-row/user-row.component';
import { ExamService } from './_services/exam.service';
import { ExamAddComponent } from './Components/exam-add/exam-add.component';
import { ListLevelsComponent } from './Components/list-levels/list-levels.component';
import { LevelEditComponent } from './Components/level-edit/level-edit.component';
import { LevelDetailComponent } from './Components/level-detail/level-detail.component';
import { LevelRowComponent } from './Components/level-row/level-row.component';
import { LevelAddComponent } from './Components/level-add/level-add.component';
import { ListLevelsResolver } from './_resolvers/list-levels.resolver';
import { LevelDetailResolver } from './_resolvers/level-detail.resolver';
import { ListClassificationsResolver } from './_resolvers/list-Classifications.resolver';
import { ListClassificationsComponent } from './Components/list-classifications/list-classifications.component';
import { ClassificationsAddComponent } from './Components/classifications-add/classifications-add.component';
import { ClassificationDetailComponent } from './Components/classification-detail/classification-detail.component';
import { ClassificationEditComponent } from './Components/classification-edit/classification-edit.component';
import { ClassificationDetailResolver } from './_resolvers/classification-detail.resolver';
import { ListQuestionsComponent } from './Components/list-questions/list-questions.component';
import { ListQuestionsResolver } from './_resolvers/list-questions.resolver';
import { QuestionsAddComponent } from './Components/questions-add/questions-add.component';
import { ExamTopresentComponent } from './Components/exam-topresent/exam-topresent.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListUserExamsComponent } from './Components/list-userExams/list-userExams.component';
import { listUserExamsResolver } from './_resolvers/list-userExams.resolver';
import { ExamToPresentDetailResolver } from './_resolvers/examtopresent-detail.resolver';
import { QuestionEditComponent } from './Components/question-edit/question-edit.component';
import { QuestionDetailResolver } from './_resolvers/question-detail.resolver';
import { ExamAssignComponent } from './Components/exam-assign/exam-assign.component';
import { ExamTopresentdetailComponent } from './Components/exam-topresentdetail/exam-topresentdetail.component';
import { PaypalComponent } from './Components/paypal/paypal.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { ExamPurchasedsuccessComponent } from './Components/exam-purchasedsuccess/exam-purchasedsuccess.component';
import { CourseService } from 'src/app/_services/course.service';
import { listUserCoursesResolver } from 'src/app/_resolvers/list-userCourses.resolver';
import { ListCoursesResolver } from 'src/app/_resolvers/list-courses.resolver';
import { ListCoursesComponent } from 'src/app/Components/list-courses/list-courses.component';
import { ListUserCoursesComponent } from 'src/app/Components/list-userCourses/list-userCourses.component';
import { CourseDetailResolver } from 'src/app/_resolvers/course-detail.resolver';
import { CourseDetailComponent } from 'src/app/Components/course-detail/course-detail.component';
import { CourseEditComponent } from 'src/app/Components/course-edit/course-edit.component';
import { CourseAddComponent } from 'src/app/Components/course-add/course-add.component';
import { CourseAssignComponent } from 'src/app/Components/course-assign/course-assign.component';
import { ListReadingsResolver } from 'src/app/_resolvers/list-readings.resolver';
import { ReadingDetailResolver } from 'src/app/_resolvers/reading-detail.resolver';
import { ListReadingsComponent } from 'src/app/Components/list-readings/list-readings.component';
import { ReadingDetailComponent } from 'src/app/Components/reading-detail/reading-detail.component';
import { ReadingEditComponent } from 'src/app/Components/reading-edit/reading-edit.component';
import { ReadingAddComponent } from 'src/app/Components/reading-add/reading-add.component';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
      ExamsComponent,
      NavComponent,
      HomeComponent,
      RegisterComponent,
      UserEditComponent,
      UserDetailComponent,
      ListUsersComponent,
      UserRowComponent,
      ExamRowComponent,
      ExamAddComponent,
      ListLevelsComponent,
      LevelRowComponent,
      LevelAddComponent,
      ListClassificationsComponent,
      ClassificationsAddComponent,
      ClassificationDetailComponent,
      ClassificationEditComponent,
      ListQuestionsComponent,
      LevelDetailComponent,
      LevelEditComponent,
      ExamEditComponent,
      ExamDetailComponent,
      QuestionsAddComponent,
      ExamTopresentComponent,
      ListUserExamsComponent,
      QuestionEditComponent,
      ExamAssignComponent,
      ExamTopresentdetailComponent,
      PaypalComponent,
      ExamPurchasedsuccessComponent,
      ListCoursesComponent,
      ListUserCoursesComponent,
      CourseDetailComponent,
      CourseEditComponent,
      CourseAddComponent,
      ListReadingsComponent,
      ReadingDetailComponent,
      ReadingEditComponent,
      ReadingAddComponent,
      LoginMobileComponent,
      CourseAssignComponent
   ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['localhost:5000', 'api-examsv1.inglesaprendeingles.com'],
        disallowedRoutes: ['localhost:5000/api/auth', 'api-examsv1.inglesaprendeingles.com/api/auth']
      }
    }),
    NgbModule,
    NgxPayPalModule
  ],
  providers: [
    AuthService,
    ErrorInterceptorProvider,
    AlertifyService,
    CourseService,
    UserService,
    ExamService,
    UserEditResolver,
    UserDetailResolver,
    ListUsersResolver,
    ListExamsResolver,
    ListLevelsResolver,
    ListClassificationsResolver,
    ClassificationDetailResolver,
    LevelDetailResolver,
    ExamDetailResolver,
    ListQuestionsResolver,
    listUserExamsResolver,
    ExamToPresentDetailResolver,
    QuestionDetailResolver,
    listUserCoursesResolver,
    ListCoursesResolver,
    CourseDetailResolver,
    ListReadingsResolver,
    ReadingDetailResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
