import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReadingModel } from 'src/app/_models/ReadingModel';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-reading-edit',
  templateUrl: './reading-edit.component.html',
  styleUrls: ['./reading-edit.component.css']
})
export class ReadingEditComponent implements OnInit {
  updateForm: FormGroup;
  reading: ReadingModel;

  constructor(private router: Router, private route: ActivatedRoute,
    private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.reading = data.reading;
    });

    this.createUpdateForm();
  }

  createUpdateForm() {
    this.updateForm = this.fb.group({
      Name: [this.reading.Name, Validators.required],
      Description: [this.reading.Description, Validators.required],
      ReadingTime: [this.reading.ReadingTime, Validators.required],
      Reading: [this.reading.Reading, Validators.required]
    });
  }
  
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  updateReading(){
    this.examService.UpdateReading(this.reading).subscribe(() => {
      this.alertify.success('Lecutra Actualizada');
      this.router.navigate(['/readings']);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
    });
    this.updateForm.reset(this.updateForm.value);
  }
}
