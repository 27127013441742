<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="registerForm" (ngSubmit)="registerCourse()">
        <h2 class="text-center text-primary">Registrar Curso</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" 
          [ngClass]="{'is-invalid': registerForm.get('Name').errors && registerForm.get('Name').touched }"
          class="form-control" formControlName="Name" placeholder="Nombre">
          <div class="invalid-feedback">Porfavor ingrese el nombre del curso</div>
        </div>

        <div class="form-group">
          <textarea class="form-control"  rows="3" [ngClass]="{'is-invalid': registerForm.get('Description').errors && registerForm.get('Description').touched }"
          class="form-control" formControlName="Description" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group">
          <input type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': registerForm.get('Price').errors && registerForm.get('Price').touched }"
          class="form-control" formControlName="Price" placeholder="Precio">
          <div class="invalid-feedback">Porfavor asigne un precio</div>
        </div>
        
        <div class="form-group">
          <input type="text"
          [ngClass]="{'is-invalid': registerForm.get('Duration').errors && registerForm.get('Duration').touched }"
          class="form-control" formControlName="Duration" placeholder="Duración">
          <div class="invalid-feedback">Asigne una duración al curso</div>
        </div>
        
        <div class="form-group">
          <input type="text"
          [ngClass]="{'is-invalid': registerForm.get('Modality').errors && registerForm.get('Modality').touched }"
          class="form-control" formControlName="Modality" placeholder="Modalidad(ejemplo : 2 clses x semana, etc Presencial o En Línea o Hibrido)">
          <div class="invalid-feedback">Asigne una modalidad al curso</div>
        </div>

        <div class="form-group">
          <select class="custom-select" [ngClass]="{'is-invalid': registerForm.get('CourseType').errors && registerForm.get('CourseType').touched }"
          class="form-control" formControlName="CourseType">
          <option hidden selected value="" >Seleccione Tipo</option>
          <option [value]="courseType.idCourseType" *ngFor="let courseType of courseTypes">{{courseType.Name}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un tipo</div>
        </div>

        <div class="">
          <h4 class="text-center">Examenes</h4>
          <div class="d-flex w-100">
            <div class="form-group d-flex w-100">
              <select class="custom-select" id="Level" (change)="onChangeLevel($event.target.value)"
              class="form-control">
              <option hidden [value]="0" >Nivel</option>
              <option [value]="level.idLevel" *ngFor="let level of levels">{{level.LevelName}}</option>
              </select>

              <select class="custom-select" id="Classification" (change)="onChangeClassification($event.target.value)"
               class="form-control">
               <option hidden [value]="0" >Clasificación</option>
                <option [value]="classification.idClassification" 
                        *ngFor="let classification of Classifications">
                        {{classification.ClassificationName}}
                </option>
              </select>

              <select id="ExamSelect" class="custom-select" class="form-control">
                <option hidden [value]="0" selected>Examen</option>
                <option [value]="exam.idExams" 
                        *ngFor="let exam of exams">
                        {{exam.Name}}
                </option>
              </select>
              <button class="btn btn-success ml-3" type="button" (click)="addExam()"><i class="fa fa-plus"></i></button>
            </div>
            
          </div>
        </div>

        <div class="form-group">
          <div *ngFor="let item of getFormArray().controls | keyvalue; let i = index" formArrayName="CourseExams">
            <div [formGroupName]="item.key">
              <div class="form-group d-flex w-100">
                <div class="w-100">
                  {{registerForm.get('CourseExams').get(item.key).get('examName').value}}
                </div>
                <button class="btn btn-danger ml-3" type="button" (click)="removeExam(item.key)"><i class="fa fa-minus"></i></button>
              </div>  
            </div>
          </div>
        </div>


        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Agregar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/courses']">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
