import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Classification } from 'src/app/_models/Classification';
import { Exam } from 'src/app/_models/Exam';
import { Level } from 'src/app/_models/Level';
import { UserExams } from 'src/app/_models/UserExams';
import { UserExamInsertRequest } from 'src/app/_models/Request/UserExamInsertRequest';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-exam-assign',
  templateUrl: './exam-assign.component.html',
  styleUrls: ['./exam-assign.component.css']
})
export class ExamAssignComponent implements OnInit {

  constructor(private router: Router, private examService: ExamService,
              private alertify: AlertifyService, private fb: FormBuilder) { }

  @Input() idUser: number;

  bsConfig: Partial<BsDatepickerConfig>;

  registerForm: FormGroup;
  userExams: UserExams[] = [];
  levels: Level[];
  Classifications: Classification[];
  exams: Exam[];
  userExamRequest: UserExamInsertRequest;

  ngOnInit() {
    
    this.loadAssignedExams();
    this.loadLevels();
    this.createRegisterForm();
  }
  
  createRegisterForm() {
    this.registerForm = this.fb.group({
      idUser: [this.idUser],
      idExam: ['', Validators.required],
      ExpirationDate: ['', Validators.required]
    },  {validator: [this.dateValidator]});
  }

  dateValidator(g: FormGroup) {
    let ExpirationDate = g.get('ExpirationDate').value;
    let b = moment();
    if (ExpirationDate != '') {
      let a = moment(ExpirationDate);
      if (a == null) {
        return null;
      }
      b = moment([b.get('year'), b.get('month'), b.get('date')]);
      return b.diff(a, 'days') < 0 ? null : {dateGreater: true};
    }
  }
  loadAssignedExams(){
    this.examService.getUserExams(this.idUser).subscribe((userExams: UserExams[]) => {
      this.userExams = userExams;
    }, error => {
      this.alertify.error(error);
    });
  }

  loadLevels() {
    this.examService.getLevels().subscribe((levels: Level[]) => {
      this.levels = levels;
    }, error => {
      this.alertify.error(error);
    });
  }

  onChangeLevel(value){
    // cargamos las classificaciones
    this.examService.getClassifications(value).subscribe((classifications: Classification[]) => {
      this.Classifications = classifications;
    }, error => {
      this.alertify.error(error);
    });
    
    // let idClassification: number = +(<HTMLSelectElement>document.getElementById('Classification')).value;
    // if (idClassification != 0) {
    //   this.onChangeClassification(idClassification);
    // }
    
    // cargamos los examenes
    // this.examService.getExamsByLevel(value).subscribe((exam: Exam[]) => {
    //   this.exams = exam;
    // }, error => {
    //   this.alertify.error(error);
    // });
  }

  onChangeClassification(value){
    let idLevel: number = +(<HTMLSelectElement>document.getElementById('Level')).value;
    // cargamos los examenes
    this.examService.GetExamsByLvlAndClas(idLevel, value).subscribe((exam: Exam[]) => {
      this.exams = exam;
    }, error => {
      this.alertify.error(error);
    });
  }

  assingExam() {
    let idExam:number = +(<HTMLSelectElement>document.getElementById('ExamSelect')).value;
    if (idExam <= 0){
      this.alertify.error('Favor de selecionar un examen');
      return;
    }

    this.userExamRequest = this.registerForm.value;
    console.log(this.userExamRequest);
    this.examService.userExamInsert(this.userExamRequest).subscribe(() => {
      this.alertify.success('Examen Asignado');
      this.router.navigateByUrl('', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/users/detail/' + this.idUser]);
      });
    }, error => {
      this.alertify.error(error);
    }, () => {
       // this.authService.login(this.user);
    });
  }
}
