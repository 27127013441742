<div class="container mt-4">
  <div class="row">
    <div class="col-sm-2">
      <!-- cosos para buscar -->
      <div class="card-footer mb-3">
        <div class="btn-group d-flex">
          <button class="btn btn-success w-100" [routerLink]="['/levels/add']">Agregar</button>
        </div>
      </div>
    </div>
    <div class="col sm-2">
      <table class="table table-hover w-100">
        <thead>
          <tr>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
            <th scope="col" class="p-1 align-middle text-center">Nombre</th>
            <th scope="col" class="p-1 align-middle text-center">Descripción</th>
            <th scope="col" class="p-1 align-middle text-center">Activo</th>
          </tr>
        </thead>
        <tbody app-level-row [Levels]="Levels"></tbody>
      </table>
    </div>
  </div>
</div>

