import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ExamService } from '../_services/exam.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserExams } from '../_models/UserExams';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class listUserExamsResolver implements Resolve<UserExams[]> {
    constructor(private examService: ExamService, private router: Router, private alertify: AlertifyService, public authService: AuthService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<UserExams[]> {
        return this.examService.getUserExams(this.authService.getUserId()).pipe(
            catchError(error => {
                this.alertify.error('problem retriving your data');
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}