import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Exam } from '../_models/Exam';
import { ReadingModel } from '../_models/ReadingModel';
import { Level } from '../_models/Level';
import { Classification } from '../_models/Classification';
import { QuestionWithAnswers } from '../_models/QuestionWithAnswers';
import { InsertQuestionsRequest } from '../_models/InserQuestionsRequest';
import { QuestionsForExamsRequest } from '../_models/QuestionsForExamsRequest';
import { UserExams } from '../_models/UserExams';
import { QualifyExamRequest } from '../_models/QualifyExamRequest';
import { ExamToPresent } from '../_models/ExamToPresent';
import { Question } from '../_models/Question';
import { Answer } from '../_models/Answer';
import { UserExamInsertRequest } from '../_models/Request/UserExamInsertRequest';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  baseUrl = environment.apiUrl;

constructor(private http: HttpClient) { }

  insertExam(exam: Exam) {
    return this.http.post(this.baseUrl + 'Examns/InsertExam', exam);
  }

  insertClassification(classification: Classification) {
    return this.http.post(this.baseUrl + 'Examns/InsertClassification', classification);
  }

  insertLevel(level: Level) {
    return this.http.post(this.baseUrl + 'Examns/InsertLevel', level);
  }

  insertQuestions(QuestionsList: InsertQuestionsRequest) {
    return this.http.post(this.baseUrl + 'Examns/InsertQuestions', QuestionsList);
  }

  userExamInsert(userExamInsertRequest: UserExamInsertRequest){
    return this.http.post(this.baseUrl + 'Examns/InsetUserExam', userExamInsertRequest);
  }

  insertReading(reading: ReadingModel){
    return this.http.post(this.baseUrl + 'Examns/InsertReading', reading);
  }

  //gets
  getExams(id: number): Observable<Exam[]>{
    return this.http.get<Exam[]>(this.baseUrl + 'Examns/GetExams/' + id);
  }

  getExamsByLevel(id: number): Observable<Exam[]>{
    return this.http.get<Exam[]>(this.baseUrl + 'Examns/GetExamsByLevel/' + id);
  }

  GetExamsByLvlAndClas(idlevel: number, idClassification: number): Observable<Exam[]>{
    return this.http.get<Exam[]>(this.baseUrl + 'Examns/GetExamsByLvlAndClas?idlevel=' + idlevel + '&idClassification=' + idClassification);
  }

  getExam(id: number): Observable<Exam>{
    return this.http.get<Exam>(this.baseUrl + 'Examns/GetExam/' + id);
  }

  getLevel(idLevel: number): Observable<Level>{
    return this.http.get<Level>(this.baseUrl + 'Examns/GetLevel/' + idLevel);
  }

  getLevels(): Observable<Level[]>{
    return this.http.get<Level[]>(this.baseUrl + 'Examns/GetLevels');
  }

  getClassification(idClassification: number): Observable<Classification>{
    return this.http.get<Classification>(this.baseUrl + 'Examns/GetClassification/' + idClassification);
  }

  getClassifications(idLevel: number): Observable<Classification[]>{
    return this.http.get<Classification[]>(this.baseUrl + 'Examns/GetClassifications/' + idLevel);
  }

  getAllClassifications(): Observable<Classification[]>{
    return this.http.get<Classification[]>(this.baseUrl + 'Examns/GetAllClassifications');
  }

  getQuestion(idQuestion: number): Observable<Question>{
    return this.http.get<Question>(this.baseUrl + 'Examns/GetQuestion/' + idQuestion);
  }

  getQAndAs(idQuestion: number):Observable<QuestionWithAnswers> {
    return this.http.get<QuestionWithAnswers>(this.baseUrl + 'Examns/GetQAndAs/' + idQuestion);
  }

  getQuestionsByClassification(idClassification: number): Observable<QuestionWithAnswers[]>{
    return this.http.get<QuestionWithAnswers[]>(this.baseUrl + 'Examns/GetQuestions/' + idClassification);
  }

  getExamnQuestions(request :QuestionsForExamsRequest): Observable<QuestionWithAnswers[]>{
    return this.http.post<QuestionWithAnswers[]>(this.baseUrl + 'Examns/GetRandomQuestions', request);
  }

  getUserExams(id: number): Observable<UserExams[]>{
    return this.http.get<UserExams[]>(this.baseUrl + 'Examns/GetExamsByUser/'+id);
  }

  getExamToPresent(id: number): Observable<ExamToPresent>{
    return this.http.get<ExamToPresent>(this.baseUrl + 'Examns/GetExamToPresent/'+id);
  }

  getReading(id: number): Observable<ReadingModel>{
    return this.http.get<ReadingModel>(this.baseUrl + 'Examns/GetReading/'+id);
  }

  getReadings(): Observable<ReadingModel[]>{
    return this.http.get<ReadingModel[]>(this.baseUrl + 'Examns/GetReadings');
  }

  QualifyExam(QualifyExam: QualifyExamRequest) {
    return this.http.post(this.baseUrl + 'Examns/QualifyExam', QualifyExam);
  }

  //updates

  UpdateExam(exam: Exam): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateExam', exam);
  }

  UpdateLevel(level: Level): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateLevel', level);
  }
  
  UpdateClassification(classification: Classification): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateClassification', classification);
  }

  UpdateQuestion(questionWansers: QuestionWithAnswers): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateQuestion', questionWansers);
  }

  UpdateAnswer(answer: Answer): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateAnswer', answer);
  }

  UpdateReading(reading: ReadingModel): Observable<boolean>{
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateReading', reading);
  }
  
}


