<div class="container mt-4">
  <div *ngIf="!PresentExamMode" class="col sm-7">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
          <th scope="col" class="p-1 align-middle text-center">Examen</th>
          <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Fecha de presentado</th>
          <th scope="col" class="p-1 align-middle text-center">Puntaje</th>
          <th scope="col" class="p-1 align-middle text-center">Fecha Límite</th>
        </tr>
      </thead>
      
      <tr *ngFor="let exam of exams; let i = index" class="p-0">
        <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{i+1}}</th>
        <td class="p-0 align-middle text-center">{{exam.ExamName}}</td>
        <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{exam.PresentedDate | date:'M/d/yy, h:mm a'}}</td>
        <td class="p-0 align-middle text-center">{{exam.Score}}</td>
        <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{exam.ExpirationDate | date:'M/d/yy, h:mm a'}}</td>
        <td class="p-0 align-middle text-center d-lg-none">{{exam.ExpirationDate | date:'M/d/yy'}}</td>
        <td class="p-0 d-none d-lg-table-cell">
          <button class="btn btn-outline-primary w-100 pr-0 pl-0" [disabled]="exam.PresentedDate != null || validateExpirationDateDate(exam.ExpirationDate)" (click)="examToPresentDatailToggle(exam.idExam, exam.idUserExams)" >Presentar <i class="fa fa-pencil-square-o"></i></button>
        </td>
        <td class="p-0 pt-1 pb-1 d-lg-none" style="max-width: 50px; min-width: 35px;">
          <button class="btn btn-outline-primary w-100 pr-0 pl-0" [disabled]="exam.PresentedDate != null || validateExpirationDateDate(exam.ExpirationDate)" (click)="examToPresentDatailToggle(exam.idExam, exam.idUserExams)" ><i class="fa fa-pencil-square-o"></i></button>
        </td>
      </tr>
    </table>
  </div>
</div>

<div *ngIf="PresentExamMode" class="container">
  <div class="justify-content-center">
      <app-exam-topresentdetail [idExam]="ExamSelected" [idUserExams]="idUserExamSelected" (PresentExamMode)="cancelExamToPresentDatail($event)"></app-exam-topresentdetail>
  </div>
</div>