<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="registerForm" (ngSubmit)="registerExam()">
        <h2 class="text-center text-primary">Registrar examen</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" 
          [ngClass]="{'is-invalid': registerForm.get('Name').errors && registerForm.get('Name').touched }"
          class="form-control" formControlName="Name" placeholder="Nombre">
          <div class="invalid-feedback">Porfavor ingrese el nombre del examen</div>
        </div>

        <div class="form-group">
          <textarea class="form-control"  rows="3" [ngClass]="{'is-invalid': registerForm.get('Description').errors && registerForm.get('Description').touched }"
          class="form-control" formControlName="Description" placeholder="Descripción para el alumno o instrucciones para el examen"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group">
          <select class="custom-select" (change)="onChangeLevel($event.target.value)" [ngClass]="{'is-invalid': registerForm.get('idLevel').errors && registerForm.get('idLevel').touched }"
          class="form-control" formControlName="idLevel">
            <option hidden selected value="" >Selecione un nivel para el examen</option>
            <option [value]="level.idLevel" *ngFor="let level of Levels">{{level.LevelName}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un nivel</div>
        </div>

        <div class="form-group">
          <div class="d-flex mb-3">
            <h4 class="text-center w-100">Classificaciones</h4>
            <button class="btn btn-success" [disabled]="levelSelected()" type="button" (click)="addClassification()"><i class="fa fa-plus"></i></button>
          </div>
          
          <div *ngFor="let item of getFormArray().controls | keyvalue; let i = index" formArrayName="Classifications">
            <div [formGroupName]="item.key">
              <div class="form-group d-flex align-items-baseline w-100">
                <div class="w-100">
                  <select class="custom-select" [ngClass]="{'is-invalid': registerForm.get('Classifications').get(item.key).get('idClassification').errors 
                  && registerForm.get('Classifications').get(item.key).get('idClassification').touched }"
                  class="form-control" formControlName="idClassification">
                    <option hidden selected value="" >Seleccione Clasificación</option>
                    <option [value]="classification.idClassification" *ngFor="let classification of Classifications">{{classification.ClassificationName}} ({{classification.QuestionCount}})</option>
                  </select>
                  <div class="invalid-feedback">Por favor seleccione una clasificación</div>
                </div>
                
                <button class="btn btn-danger ml-3" type="button" (click)="removeClassification(item.key)"><i class="fa fa-minus"></i></button>
              </div>  
            </div>
          </div>
        </div>

        <div class="form-group">
          <input type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': registerForm.get('QuestionTime').errors && registerForm.get('QuestionTime').touched }"
          class="form-control" formControlName="QuestionTime" placeholder="Tiempo para por pregunta (Segundos)">
          <div class="invalid-feedback">Por favor asigne un tiempo para pasar a la siguiente pregunta</div>
        </div>
        
        <div class="form-group">
          <input type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': registerForm.get('QuestionNumber').errors && registerForm.get('QuestionNumber').touched }"
          class="form-control" formControlName="QuestionNumber" placeholder="Numero de preguntas">
          <div class="invalid-feedback">Agregue un numero de pregunta para el examen</div>
        </div>
        
        <div class="form-group">
          <input type="text"  (keypress)="onlyNumberKey($event)" maxlength="6"
          [ngClass]="{'is-invalid': registerForm.get('Price').errors && registerForm.get('Price').touched }"
          class="form-control" formControlName="Price" placeholder="Precio">
          <div class="invalid-feedback">Agregue un numero de pregunta para el examen</div>
        </div>
        
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input formControlName="SendQualification" type="checkbox" class="custom-control-input" id="SendQualification">
            <label class="custom-control-label" for="SendQualification">Mandar calificación al estudiante</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input formControlName="RespectQuestionOrder" type="checkbox" class="custom-control-input" id="RespectQuestionOrder">
            <label class="custom-control-label" for="RespectQuestionOrder">Respetar orden de preguntas</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input (change)="onChangeHasReading()" formControlName="HasReading" type="checkbox" class="custom-control-input" id="HasReading">
            <label class="custom-control-label" for="HasReading">Tiene Lectura</label>
          </div>
        </div>

        <div *ngIf="ShowReadings" class="form-group">
          <select class="custom-select" [ngClass]="{'is-invalid': registerForm.get('idReading').errors && registerForm.get('idReading').touched }"
          class="form-control" formControlName="idReading">
            <option hidden selected value="" >Seleccione una Lectura</option>
            <option [value]="reading.idReading" *ngFor="let reading of Readings">{{reading.Name}}</option>
          </select>
          <div class="invalid-feedback">Porfavor selecione una Lectura</div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Agregar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/exams']">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
