import { Injectable } from '@angular/core';
import { Classification } from '../_models/Classification';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ExamService } from '../_services/exam.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { QuestionWithAnswers } from '../_models/QuestionWithAnswers';

@Injectable()
export class ListQuestionsResolver implements Resolve<QuestionWithAnswers[]> {
    constructor(private examService: ExamService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<QuestionWithAnswers[]> {
        return this.examService.getQuestionsByClassification(route.params['id']).pipe(
            catchError(error => {
                this.alertify.error('problem retriving your data');
                this.router.navigate(['/classifications']);
                return of(null);
            })
        );
    }
}