<div class="text-center mt-5">
  <div class="center_div">
    <div class="container d-flex justify-content-center">
      <form *ngIf="!loggedIn()" #loginFormMob="ngForm" id="loginFrmMobile" class="d-flex flex-column justify-content-center" (ngSubmit)="login()">
        <input class="form-control mr-sm-2" type="text" id="usrName" name="username" placeholder="Username" required [(ngModel)]="model.username"l>
        <input class="form-control mr-sm-2" type="password" id="usrPswod" name="UserPassWord" placeholder="Password" required [(ngModel)]="model.UserPassWord">
        <button [disabled]="!loginFormMob.valid" id="login-btn" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
      </form>
    </div>
  </div>
</div>
