import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { QuestionWithAnswers } from 'src/app/_models/QuestionWithAnswers';

@Component({
  selector: 'app-list-questions',
  templateUrl: './list-questions.component.html',
  styleUrls: ['./list-questions.component.scss']
})
export class ListQuestionsComponent implements OnInit {
  Questions: QuestionWithAnswers[];
  Classification: Classification;
  AddQuestionMode: boolean=false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.Questions = data.questions;
    });

    this.route.data.subscribe(data => {
      this.Classification = data.classification;
    });
  }

  showTable: boolean;

  toggle(item, index) {
    let selector = `.row-num-${index}`;
    document.querySelector(selector).classList.toggle('d-none');
    this.showTable = true;
  }

  AddQuestionToggle() {
    this.AddQuestionMode = true;
  }

  cancelAddQuestionMode(AddQuestionMode: boolean) {
    this.AddQuestionMode = AddQuestionMode;
  }

}
