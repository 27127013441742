<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="updateForm" (ngSubmit)="updateReading()">
        <h2 class="text-center text-primary">Actualizar Lectura</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" [(ngModel)]="reading.Name"
          [ngClass]="{'is-invalid': updateForm.get('Name').errors && updateForm.get('Name').touched }"
          class="form-control" formControlName="Name" placeholder="Nombre de Lectura">
          <div class="invalid-feedback">Porfavor ingrese el nombre de la lectura</div>
        </div>

        <div class="form-group">
          <textarea [(ngModel)]="reading.Description" class="form-control"  rows="3" [ngClass]="{'is-invalid': updateForm.get('Description').errors && updateForm.get('Description').touched }"
          class="form-control" formControlName="Description" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group">
          <input [(ngModel)]="reading.ReadingTime" type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': updateForm.get('ReadingTime').errors && updateForm.get('ReadingTime').touched }"
          class="form-control" formControlName="ReadingTime" placeholder="Tiempo de lectura">
          <div class="invalid-feedback">Porfavor ingrese el tiempo de la lectura</div>
        </div>

        <div class="form-group">
          <textarea [(ngModel)]="reading.Reading" class="form-control"  rows="3" [ngClass]="{'is-invalid': updateForm.get('Reading').errors && updateForm.get('Reading').touched }"
          class="form-control" formControlName="Reading" placeholder="Lectura"></textarea>
          <div class="invalid-feedback">Porfavor agregue una Lectura</div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!updateForm.dirty || !updateForm.valid" type="submit">Actualizar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/readings']">Cancelar</button>
        </div>
      </form>
    </div>
  </div>
</div>
