import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { User } from 'src/app/_models/User';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-User-edit',
  templateUrl: './User-edit.component.html',
  styleUrls: ['./User-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  user: User;

  constructor(private router: Router, private userService: UserService,
              private route: ActivatedRoute, private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.user = data.user;
    });
  }

  getAge() {
    return moment().diff(this.user.DateOfBirth, 'years');
  }

  updateUser(){
    this.userService.UpdateUser(this.user).subscribe(() => {
      this.router.navigate(['/home']);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
       //this.authService.login(this.user);
    });
    //this.examService.UpdateExam(this.updateForm.value);
    this.alertify.success('Profile updated successfully');
    this.editForm.reset(this.user);
  }
}
