import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.scss']
})
export class LoginMobileComponent implements OnInit {
  model: any = {};

  constructor(public authService: AuthService, private alertify: AlertifyService, private router: Router, private userService: UserService) { }
  hideActions: boolean;

  ngOnInit() {
    if (this.authService.logguedIn()) {
      this.hasRol();
    }
  }

  login() {
    this.authService.login(this.model).subscribe(next => {
      this.hasRol();
      this.alertify.success('logued');
    }, error => {
      this.alertify.error(error);
    }, () => {
      location.replace('/exams');
    });

   
  }

  loggedIn() {
    return this.authService.logguedIn();
  }

  hasRol() {
    this.userService.hasRol(this.getUserId()).subscribe((hide: boolean) => {
      this.hideActions = hide;
    });
  }

  getUserId(): number {
    return this.authService.getUserId();
  }
}
