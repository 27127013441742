import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';
import { Level } from '../../_models/Level';
import { Classification } from '../../_models/Classification';
import { Exam } from 'src/app/_models/Exam';
import { ReadingModel } from 'src/app/_models/ReadingModel';

@Component({
  selector: 'app-exam-add',
  templateUrl: './exam-add.component.html',
  styleUrls: ['./exam-add.component.css']
})
export class ExamAddComponent implements OnInit {
  registerForm: FormGroup;
  exam: Exam;
  Levels: Level[];
  Classifications: Classification[];
  Readings: ReadingModel[];
  ShowReadings: boolean;

  constructor(private router: Router, private route: ActivatedRoute, 
              private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }
  
  ngOnInit() {
    this.createRegisterForm();

    this.route.data.subscribe(data => {
      this.Levels = data.Levels;
    });
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      Name: ['', Validators.required],
      Description: ['', Validators.required],
      idLevel: ['', Validators.required],
      QuestionTime: ['', Validators.required],
      QuestionNumber: ['', Validators.required],
      Price: ['', Validators.required],
      HasReading: [false],
      SendQualification: [true],
      RespectQuestionOrder: [false],
      idReading: [''],
      Classifications: this.fb.array([])
    });
  }

  addClassification(){
    const Classifications = this.registerForm.get('Classifications') as FormArray;
    if (Classifications.length <= this.Classifications.length - 1 )
    {
      Classifications.push(this.createClassficationsFrom());
    }else
    {
      this.alertify.warning("Solo existen classificaciones " + this.Classifications.length + " para este nivel.");
    }
  }

  levelSelected(): boolean {
    if (this.registerForm.get('idLevel').value != '')
    {
      return false;
    }
    return true;
  }

  removeClassification(id: number) {
    const Classifications = this.registerForm.get('Classifications') as FormArray;
    Classifications.removeAt(id);
  }

  createClassficationsFrom(): FormGroup{
    return new FormGroup ({
      idExams: new FormControl(0),
      idClassification: new FormControl('', Validators.required)
    });
  }

  public getFormArray(): FormArray{
    var form = this.registerForm.get('Classifications') as FormArray;
    return form;
  }
  
  onChangeLevel(value){
    this.examService.getClassifications(value).subscribe((classifications: Classification[]) => {
      this.Classifications = classifications;
    }, error => {
      this.alertify.error(error);
    });
  }

  onChangeHasReading(){
    let value = this.registerForm.get('HasReading').value;
    if (value){
      this.examService.getReadings().subscribe((readings: ReadingModel[]) => {
        this.Readings = readings;
        this.ShowReadings = true;
      }, error => {
        this.alertify.error(error);
      });
    }
    else
    {
      this.ShowReadings = false;
      this.Readings = null;
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  registerExam(){
    
    if (this.registerForm.valid) {
        if (this.hasClassification()){
          this.exam = Object.assign({}, this.registerForm.value);
          this.exam.Active = true;
          if (this.hasEnoughQuestions(this.exam.QuestionNumber))
          {
            this.examService.insertExam(this.exam).subscribe(() => {
              this.alertify.success('Examen Agregado');
              this.router.navigate(['/exams']);
            }, error => {
              this.alertify.error(error);
            }, () => {
              //this.authService.login(this.user);
            });
          }
          else
          {
            let msg = `El numero de preguntas (${this.exam.QuestionNumber}) excede al numero de preguntas en las clasificaciones (${this.countQuestionsInClasifications()})`;
            this.alertify.error(msg);  
          }
        }
        else {
          this.alertify.error("Debe agregar almenos una clasificación");
        }
        
    }
  }

  hasClassification(): boolean {
    if (this.getFormArray().length > 0)
    {
      return true;
    }
    return false;
  }

  hasEnoughQuestions(QuestionsNumber: number): boolean {
    if (this.countQuestionsInClasifications() < QuestionsNumber)
    {
      return false;
    }
    return true;
  }

  countQuestionsInClasifications(): number {
    let QuestionCount: number = 0;
    this.getFormArray().controls.forEach(element => {
      let examClassification = {idExams: 0, idClassification: 0}
      examClassification = element.value;
      
      QuestionCount = QuestionCount + this.Classifications.find(x => x.idClassification == examClassification.idClassification).QuestionCount;
    });

    return QuestionCount;
  }
}
