<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="registerForm" (ngSubmit)="registerLevel()">
        <h2 class="text-center text-primary">Registrar Nivel</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" 
          [ngClass]="{'is-invalid': registerForm.get('LevelName').errors && registerForm.get('LevelName').touched }"
          class="form-control" formControlName="LevelName" placeholder="Nombre del Nivel">
          <div class="invalid-feedback">Porfavor ingrese el nombre del Nivel</div>
        </div>

        <div class="form-group">
          <textarea class="form-control"  rows="3" [ngClass]="{'is-invalid': registerForm.get('LevelDescription').errors && registerForm.get('LevelDescription').touched }"
          class="form-control" formControlName="LevelDescription" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Register</button>
          <button class="btn btn-default" type="button" [routerLink]="['/levels']">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
