import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';

@Component({
  selector: 'app-list-classifications',
  templateUrl: './list-classifications.component.html',
  styleUrls: ['./list-classifications.component.scss']
})
export class ListClassificationsComponent implements OnInit {
  classifications: Classification[];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.classifications = data.classifications;
    });
  }

}
