import { Component, Input, OnInit } from '@angular/core';
import { Level } from 'src/app/_models/Level';

@Component({
  selector: 'tbody[app-level-row]',
  styleUrls: ['./level-row.component.scss'],
  template: `
  <tr *ngFor="let Level of Levels" class="p-0">
    <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{Level.idLevel}}</th>
    <td class="p-0 align-middle">{{Level.LevelName}}</td>
    <td class="p-0 align-middle text-center">{{Level.LevelDescription}}</td>
    <td class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=Level.Active disabled></td>
    <td class="p-0">
      <div class="btn-group d-flex">
        <button class="btn btn-outline-primary w-100" [routerLink]="['/levels/edit/', Level.idLevel]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
        <button class="btn btn-outline-info w-100" [routerLink]="['/levels/detail/', Level.idLevel]" routerLinkActive="router-link-active" ><i class="fa fa-info"></i></button>
      </div>
    </td>
  </tr>
  `
})
export class LevelRowComponent implements OnInit {
  @Input() Levels: Level[];
  constructor() { }

  ngOnInit() {
  }

}
