import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReadingModel } from 'src/app/_models/ReadingModel';

@Component({
  selector: 'app-reading-detail',
  templateUrl: './reading-detail.component.html',
  styleUrls: ['./reading-detail.component.css']
})
export class ReadingDetailComponent implements OnInit {
  reading: ReadingModel;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.reading = data.reading;
    });
  }

}
