import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { CourseService } from '../_services/course.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Course } from '../_models/Course';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class listUserCoursesResolver implements Resolve<Course[]> {
    constructor(private courseService: CourseService, private router: Router, private alertify: AlertifyService, public authService: AuthService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Course[]> {
        return this.courseService.GetCoursesByUser(this.authService.getUserId()).pipe(
            catchError(error => {
                this.alertify.error('problem retriving your data');
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}