<div class="container mt-4">
  <div class="row">
    <div class="col-sm-2">
      <div class="card-footer">
        <div class="btn-group d-flex">
          <button class="btn btn-success w-100" [routerLink]="['/readings/add']">Agregar</button>
        </div>
      </div>
    </div>
    <div class="col sm-2">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
            <th scope="col" class="p-1 align-middle text-center">Nombre</th>
            <th scope="col" class="p-1 align-middle text-center">Tiempo de lectura</th>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let reading of readings" class="p-1">
            <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{reading.idReading}}</th>
            <td class="p-0 align-middle">{{reading.Name}}</td>
            <td class="p-0 align-middle text-center">{{reading.ReadingTime}}</td>
            <td class="p-0 align-middle d-none d-lg-table-cell">{{reading.Description}}</td>
            <!-- <td *ngIf="hideActions" class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=course.Active disabled></td> -->
            <td class="p-0">
              <div class="btn-group d-flex">
                <button class="btn btn-outline-primary w-100" [routerLink]="['/readings/edit/', reading.idReading]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
                <button class="btn btn-outline-info w-100" [routerLink]="['/readings/detail/', reading.idReading]" routerLinkActive="router-link-active" ><i class="fa fa-info"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
