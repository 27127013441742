<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="registerForm" (ngSubmit)="registerReading()">
        <h2 class="text-center text-primary">Registrar Lectura</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" 
          [ngClass]="{'is-invalid': registerForm.get('Name').errors && registerForm.get('Name').touched }"
          class="form-control" formControlName="Name" placeholder="Nombre de la lectura">
          <div class="invalid-feedback">Porfavor ingrese el nombre de la lectura</div>
        </div>

        <div class="form-group">
          <textarea class="form-control"  rows="3" [ngClass]="{'is-invalid': registerForm.get('Description').errors && registerForm.get('Description').touched }"
          class="form-control" formControlName="Description" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>

        <div class="form-group">
          <input type="text"  (keypress)="onlyNumberKey($event)" maxlength="3"
          [ngClass]="{'is-invalid': registerForm.get('ReadingTime').errors && registerForm.get('ReadingTime').touched }"
          class="form-control" formControlName="ReadingTime" placeholder="Tiempo de lectura">
          <div class="invalid-feedback">Porfavor asigne un tiempo para la lectura</div>
        </div>
        
        <div class="form-group">
          <textarea class="form-control"  rows="3" [ngClass]="{'is-invalid': registerForm.get('Reading').errors && registerForm.get('Reading').touched }"
          class="form-control" formControlName="Reading" placeholder="Lectura"></textarea>
          <div class="invalid-feedback">Porfavor agregue una lectura</div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Register</button>
          <button class="btn btn-default" type="button" [routerLink]="['/readings']">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
