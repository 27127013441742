import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import {
  IPayPalConfig,
  ICreateOrderRequest 
} from 'ngx-paypal';
import { Course } from 'src/app/_models/Course';
import { Exam } from 'src/app/_models/Exam';
import { UserCourseInsertRequest } from 'src/app/_models/Request/UserCourseInsertRequest';
import { UserExamInsertRequest } from 'src/app/_models/Request/UserExamInsertRequest';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CourseService } from 'src/app/_services/course.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css']
})
export class PaypalComponent implements OnInit {
  @Output() cancelPayment = new EventEmitter();
  @Input() idExam: number;
  @Input() Type: number;
  
  userExamRequest: UserExamInsertRequest;
  userCourseRequest: UserCourseInsertRequest;
  public payPalConfig?: IPayPalConfig;
  public showPaypalButtons: boolean;
  Name: string;
  price: string;

  constructor(private router: Router, private alertify: AlertifyService, 
              private examService: ExamService, public authService: AuthService,
              private courseService: CourseService) { }

  ngOnInit() {
    
    this.initConfig();
    this.showPaypalButtons = true;
  }

  loadExam() {
    this.examService.getExam(this.idExam).subscribe((exam: Exam) => {
      this.price = exam.Price + '.00';
      this.Name = exam.Name;
    }, error => {
      this.alertify.error(error);
    });
  }

  loadCourse() {
    this.courseService.getCourse(this.idExam).subscribe((course: Course) => {
      this.price = course.Price + '.00';
      this.Name = course.Name;
    }, error => {
      this.alertify.error(error);
    });
  }

  private initConfig(): void {
      this.loadData();
      
      this.payPalConfig = {
      currency: 'MXN',
      clientId: 'AVFQrQoxTu7lSYIXWq-OQaCTCx_8678uGNWJIwE4pGb2CEn9tdxaygN7S7ccGN8XHHSu_BTmbjxCTUwC',
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'MXN',
              value: this.price,
              breakdown: {
                item_total: {
                  currency_code: 'MXN',
                  value: this.price
                }
              }
            },
            items: [
              {
                name: this.Name,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'MXN',
                  value: this.price,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.assingData();
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        this.alertify.error(err);
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }

  // pay() {
  //   this.showPaypalButtons = true;
  // }

  // back(){
  //   this.showPaypalButtons = false;
  // }

  cancel() {
    this.cancelPayment.emit(false);
    this.alertify.message('cancelled');
  }

  loadData() {
    if (this.Type == 1)
      {
        this.loadExam();
      }
      if (this.Type == 2)
      {
        this.loadCourse();
      }
  }

  assingData() {
    if (this.Type == 1) {
      this.assingExam();
    }
     
    if (this.Type == 2) {
      this.assingCourse();
    }
  }

  assingExam() {
    this.userExamRequest = {idExam: this.idExam, idUser: this.authService.getUserId()};
    console.log(this.userExamRequest);
    this.examService.userExamInsert(this.userExamRequest).subscribe(() => {
      this.alertify.success("Examen adquirido");
      this.router.navigateByUrl('', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/paymentsuccess']);
      });
    }, error => {
      this.alertify.error(error);
    }, () => {
    });
  }

  assingCourse() {
    this.userCourseRequest = {IdCourse: this.idExam, idUser: this.authService.getUserId()};
    console.log(this.userCourseRequest);
    this.courseService.insertUserCourse(this.userCourseRequest).subscribe(() => {
      this.alertify.success("Curso adquirido");
      this.router.navigateByUrl('', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/mycourses']);
      });
    }, error => {
      this.alertify.error(error);
    }, () => {
    });
  }
}

