<div *ngIf="!registerMode" class="container">
  <ngb-carousel *ngIf="images" class="d-flex flex-wrap align-items-center">
    <ng-template *ngFor="let item of images" ngbSlide>
      <div class="text-center">
        <div class="wrapper">
          <img class="img-fluid" [src]="item" alt="Responsive image">
        </div>
        <!-- <div class="carousel-caption">
          <h3>First Slide</h3>
          <a href="https://www.w3schools.com/" target="_blank">Visit W3Schools!</a>
        </div> -->
      </div>
    </ng-template>
  </ngb-carousel>
  </div>

  <div class="container mt-5 mb-5">
    <div *ngIf="!registerMode" style="text-align: center">
      <h1>Conoce tu nivel de inglés</h1>
      <h4>registrate para comenzar</h4>
      <div class="text-center mt-3">
        <button class="btn btn-primary btn-register btn-lg mr-2" (click)="registerToggle()">Registrarse</button>
        <button class="btn btn-info btn-black-label btn-lg">Saber más</button>
      </div>
    </div>

  <div *ngIf="registerMode" class="container">
    <div class="row justify-content-center">
        <app-register (cancelRegister)="cancelRegisterMode($event)"></app-register>
    </div>
  </div>
</div>