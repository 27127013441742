import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { Level } from 'src/app/_models/Level';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-classification-detail',
  templateUrl: './classification-detail.component.html',
  styleUrls: ['./classification-detail.component.scss']
})
export class ClassificationDetailComponent implements OnInit {
  Level: Level = {LevelName: "", idLevel: 0, LevelDescription: "", Active: true};
  classification: Classification;
  constructor(private route: ActivatedRoute, private examService: ExamService, private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.classification = data.classification;
    });

    this.getLevelInfo(this.classification.idLevel);
  }

  getLevelInfo(idLevel: number){
    this.examService.getLevel(idLevel).subscribe((level: Level) => {
      this.Level = level;
    }, error => {
      this.alertify.error(error);
    });
  }
}
