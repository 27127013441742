<div class="container mt-4">
  <div *ngIf="!AddQuestionMode" class="row">
    <div class="col-sm-2">
      <!-- cosos para buscar -->
      <div class="card-footer">
        <div class="btn-group d-flex">
          <button class="btn btn-success w-100" (click)="AddQuestionToggle()">Agregar preguntas</button>
        </div>
      </div>
    </div>
    <div class="col sm-7">
      <h2 class="text-center text-primary mb-4">Preguntas de clasificación {{Classification.ClassificationName}}</h2>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col" class="p-1 align-middle text-center id-column d-none d-lg-table-cell">Id</th>
            <th scope="col" class="p-1 align-middle text-center">Pregunta</th>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell w-25">No. Respuestas</th>
            <th scope="col" class="p-1 align-middle text-center d-lg-none">Rptas</th>
            <th scope="col" class="p-1 align-middle text-center">Activo</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let q of Questions; let i = index">
            <tr>
              <th scope="row" class="p-0 align-middle text-center id-column d-none d-lg-table-cell">{{i+1}}</th>
                <td class="p-0 align-middle" id="Question">{{q.Question.Question}}</td>
                <td class="p-0 align-middle text-center">{{q.Question.OptionsNumber}}</td>
                <td class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=q.Question.Active disabled></td>
                <td class="p-0 align-middle" width="5%" (click)="toggle(q, i)">
                  <div class="btn-group d-flex" style="max-width: 80px;">
                    <button class="btn btn-outline-primary w-100" [routerLink]="['/question/edit/', q.Question.idQuestion]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
                    <button class="btn btn-outline-info w-100" aria-hidden="true" ><i class="fa fa-eye"></i></button>
                  </div>
                </td>
            </tr>
            <tr class="d-none row-num-{{ i }} ">
              <td *ngIf="showTable" colspan="4">
                <div class="row text-center">
                    <table class="table w-100">
                      <thead>
                        <tr>
                          <th scope="col" class="p-1 align-middle text-center">Respuesta</th>
                          <th scope="col" class="p-1 align-middle text-center">Correcta</th>
                        </tr>
                      </thead>
                      <tbody class="w-100">
                        <tr *ngFor="let item of Questions[i].Answers; let i = index">
                          <td>{{item.Answer}}</td>
                          <td class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=item.IsCorrect disabled></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="form-group text-center w-100">
      <button class="btn btn-success" type="button" [routerLink]="['/classifications']">Regresar</button>
    </div>
  </div>

  <div *ngIf="AddQuestionMode">
    <div class="row justify-content-center">
      <div>
          <app-questions-add [Classification]=(Classification) (cancelRegister)="cancelAddQuestionMode($event)"></app-questions-add>
      </div>
    </div>
  </div>
</div>


