<div class="container mt-4">
  <div *ngIf="!paymentMode" class="row">
    <div *ngIf="hideActions" class="col-sm-2">
      <div class="card-body">
        <div class="form-check">
          <input class="form-check-input"  (change)="onChangeActiveFilter($event.target.value)"type="radio" name="RadioActiveFilter" id="Option1" value="1" checked>
          <label class="form-check-label" for="Option1">
            Activos
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" (change)="onChangeActiveFilter($event.target.value)" type="radio" name="RadioActiveFilter" id="Option2" value="2">
          <label class="form-check-label" for="Option2">
            Inactivos
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" (change)="onChangeActiveFilter($event.target.value)" type="radio" name="RadioActiveFilter" id="Option3" value="0">
          <label class="form-check-label" for="Option3">
            Todos
          </label>
        </div>
      </div>
      <div class="card-footer">
        <div class="btn-group d-flex">
          <button class="btn btn-success w-100" [routerLink]="['/courses/add']">Agregar</button>
        </div>
      </div>
    </div>
    <div class="col sm-7">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
            <th scope="col" class="p-1 align-middle text-center">Nombre</th>
            <th scope="col" class="p-1 align-middle text-center">Precio</th>
            <th scope="col" *ngIf="hideActions" class="p-1 align-middle text-center d-none d-lg-table-cell">Duración</th>
            <th scope="col" *ngIf="hideActions" class="p-1 align-middle text-center d-none d-lg-table-cell">Modalidad</th>
            <th scope="col" *ngIf="hideActions" class="p-1 align-middle text-center d-none d-lg-table-cell">Tipo</th>
            <th scope="col" *ngIf="hideActions" class="p-1 align-middle text-center">Activo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let course of Courses" class="p-0">
            <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{course.idCourses}}</th>
            <td class="p-0 align-middle">{{course.Name}}</td>
            <td class="p-0 align-middle text-center">${{course.Price}}.00</td>
            <td *ngIf="hideActions" class="p-0 align-middle text-center d-none d-lg-table-cell">{{course.Duration}}</td>
            <td *ngIf="hideActions" class="p-0 align-middle text-center d-none d-lg-table-cell">{{course.Modality}}</td>
            <td *ngIf="hideActions" class="p-0 align-middle text-center d-none d-lg-table-cell">{{course.TypeName}}</td>
            <td *ngIf="hideActions" class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=course.Active disabled></td>
            <td class="p-0 align-middle">
              <div class="btn-group d-flex">
                <!-- <button class="btn btn-outline-primary w-100" [routerLink]="['/payment']" [state]="{Price: 10}" routerLinkActive="router-link-active" ><i class="fa fa-paypal"></i></button> -->
                <button class="btn btn-outline-primary w-100" (click)="paymentToggle(course.idCourses)"><i class="fa fa-paypal"></i></button>
                <button *ngIf="hideActions" class="btn btn-outline-primary w-100" [routerLink]="['/courses/edit/', course.idCourses]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
                <button *ngIf="hideActions" class="btn btn-outline-info w-100" [routerLink]="['/courses/detail/', course.idCourses]" routerLinkActive="router-link-active" ><i class="fa fa-info"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="paymentMode">
    <div class="row justify-content-center">
      <div>
          <app-paypal [idExam]="selectedCourseId" [Type]="2" (cancelPayment)="cancelPaymentMode($event)"></app-paypal>
      </div>
    </div>
  </div>
</div>
