import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ExamToPresent } from 'src/app/_models/ExamToPresent';
import { QualifyExamRequest } from 'src/app/_models/QualifyExamRequest';
import { QuestionWithAnswers } from 'src/app/_models/QuestionWithAnswers';
import { ReadingModel } from 'src/app/_models/ReadingModel';
import { UserAnswer } from 'src/app/_models/UserAnswer';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ExamService } from 'src/app/_services/exam.service';
import { QuestionsForExamsRequest } from '../../_models/QuestionsForExamsRequest'; 

@Component({
  selector: 'app-exam-topresent',
  templateUrl: './exam-topresent.component.html',
  styleUrls: ['./exam-topresent.component.css'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class ExamTopresentComponent implements OnInit {
  @ViewChild('myCarousel') myCarousel: NgbCarousel;
  counter: number;
  Questions: QuestionWithAnswers[] = [];
  examToPresent: ExamToPresent;
  QualifyExamRequest: QualifyExamRequest = { exam: null, answers: [], idUser: this.getUserId(), IdUserExam: 0 };
  UserAnswers: UserAnswer[] = [];
  Timer: any;
  clicked: boolean = false;
  showReading: boolean = false;
  reading: ReadingModel = { Name: '', Description: '', Reading: '', ReadingTime: 0, idReading: 0};

  constructor(public authService: AuthService, private route: ActivatedRoute,
              config: NgbCarouselConfig, private examService: ExamService, private alertify: AlertifyService) {
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
    config.pauseOnFocus = false;
    config.wrap = false;
  }

  ngAfterViewInit() {
    this.myCarousel.pause();
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.examToPresent = data.ExamToPresent;
      this.showReading = this.examToPresent.Exam.HasReading;
    });
    //this.exam.QuestionTime = 10;
    if (this.examToPresent.Exam.HasReading) {
      this.getReading(this.examToPresent.Exam.idReading);
    }
    this.startTimer(this.examToPresent.Exam.QuestionTime, false);
    this.getQuestions();
  }

  getReading(id: number) {
    this.examService.getReading(id).subscribe((Reading: ReadingModel) => {
      this.reading = Reading;
      this.myCarousel.interval = this.reading.ReadingTime * 1000;
      this.myCarousel.pause();
      clearTimeout(this.Timer);
      this.startTimer(this.reading.ReadingTime, true);
    }, error => {
      this.alertify.error(error);
    });
  }

  getQuestions(){
    let request :QuestionsForExamsRequest = {MaxQuestions: this.examToPresent.Exam.QuestionNumber, idExam: this.examToPresent.Exam.IdExams};
    //console.log(request);
    this.examService.getExamnQuestions(request).subscribe((questions: QuestionWithAnswers[]) => {
      this.Questions = questions;
    }, error => {
      this.alertify.error(error);
    });
  }

  sendQualifyExamREquest() {
    this.examService.QualifyExam(this.QualifyExamRequest).subscribe(() => {
      this.alertify.success('Examen Enviado');
    }, error => {
      this.alertify.error(error);
    }, () => {
    });
  }

  nextSlide(){
    if (parseInt(this.myCarousel.activeId) == this.Questions.length)
    {
      this.myCarousel.next();
      this.myCarousel.pause();
      // hacer peticion par calificar el examen
      this.QualifyExamRequest.answers = this.UserAnswers;
      this.QualifyExamRequest.exam = this.examToPresent.Exam;
      this.QualifyExamRequest.IdUserExam = this.examToPresent.IdUserExam;
      this.sendQualifyExamREquest();
    }
    else {
      this.myCarousel.next();
      this.myCarousel.interval = this.examToPresent.Exam.QuestionTime * 1000;
      this.myCarousel.pause();
      clearTimeout(this.Timer);
      this.startTimer(this.examToPresent.Exam.QuestionTime, false);
      this.clicked = false;
    }
  }

  startTimer(seconds: number, isReading: boolean) {
    this.setCounterValue(seconds);
    this.Timer = setInterval(() => {
      seconds = this.geCounertValue() - 1;
      this.setCounterValue(seconds);
      if(seconds === 0){ 
        clearInterval(this.Timer);
        if (parseInt(this.myCarousel.activeId) < this.Questions.length)
        {
          if (isReading) {
            this.nextSlide();
          }
          else {
            this.onSlide();
          }
        }
        else
        {
          // se pasa al ultimo slide
          this.onSlide();
        }
      }
    }, 1000);
  }

  addAnswer(idQuestion: number, idAnswer: number) {
    this.clicked = true;
    //console.log(idAnswer);
    let value = this.UserAnswers.find(x => x.idQuestion == idQuestion);
    if (value == undefined) {
      this.UserAnswers.push({idQuestion: idQuestion, idAnswer: idAnswer});
    }
    else
    {
        this.UserAnswers.forEach(x => {
          if (x.idQuestion == idQuestion){
            if (idAnswer != 0)
            {
              x.idAnswer = idAnswer;
            }
          }
        });
    }

    this.nextSlide();
  }

  geCounertValue(): number{
    return this.counter;
  }

  setCounterValue(value: number){
    this.counter = value;
  }

  getUserId(): number {
    return this.authService.getUserId();
  }

  onSlide() {
    if(parseInt(this.myCarousel.activeId) != 0)
    {
      if (!this.clicked){
        let currid: number = parseInt(this.myCarousel.activeId) - 1;
        this.addAnswer(this.Questions[currid].Question.idQuestion, 0);
      }
    }
  }
}

