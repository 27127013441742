<div class="container mt-4">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
        <th scope="col" class="p-1 align-middle">Nombre</th>
        <th scope="col" class="p-1 align-middle text-center">Localidad</th>
        <th scope="col" class="p-1 align-middle text-center">Género</th>
        <th scope="col" class="p-1 align-middle d-none d-lg-table-cell">E-Mail</th>
        <th scope="col" class="p-1 align-middle text-center">Editar/ver</th>
      </tr>
    </thead>
    <tbody app-user-row [users]="users"></tbody>
  </table>
</div>