<form [formGroup]="registerForm">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" class="p-1 align-middle text-center w-75">Curso</th>
      </tr>
    </thead>
    <tr>
      <td class="p-2 align-middle text-center">
        <div class="form-group">
          <select id="CourseSelect" [ngClass]="{'is-invalid': registerForm.get('idCourse').errors && registerForm.get('idCourse').touched }" formControlName="idCourse" class="custom-select" class="form-control">
            <option hidden selected value="" >Seleccione un curso</option>
            <option [value]="course.idCourses" 
                    *ngFor="let course of courses">
                    {{course.Name}}
            </option>
          </select>
          <div class="invalid-feedback">Porfavor selecione un Curso</div>
        </div>
      </td>
      <td class="p-2 align-middle text-center">
        <div class="form-group">
          <button class="btn btn-outline-primary w-100 pr-0 pl-0" style="min-width: 100px;" (click)="courseAssing()" [disabled]="!registerForm.valid" type="submit" >Asignar <i class="fa fa-pencil-square-o"></i></button>
        </div>
      </td>
    </tr>
  </table>
  
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" class="p-1 align-middle text-center">Id</th>
        <th scope="col" class="p-1 w-100">Nombre</th>
      </tr>
    </thead>
    
    <tr *ngFor="let course of userCourses; let i = index" class="p-0">
      <th scope="row" class="p-0 align-middle text-center">{{i+1}}</th>
      <td class="p-0 ">{{course.Name}}</td>
    </tr>
  </table>
</form>