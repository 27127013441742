<div class="text-center mt-5">
  <div class="center_div">
    <div class="container mt-4 text-left">
      <tabset class="member-tabset">
        <tab heading='Informacion de Examen'>
          <h4>Nombre</h4>
          <p>{{Exam.Name}}</p>
          <h4>Nivel</h4>
          <p>{{Level.LevelName}}</p>
          <h4>Clasificaciones</h4>
          <p *ngFor="let item of Classifications" >{{item.ClassificationName}}</p>
          <h4>Número de preguntas</h4>
          <p>{{Exam.QuestionNumber}}</p>
          <h4>Tiempo por pregunta</h4>
          <p>{{Exam.QuestionTime}}</p>
          <h4>Activo</h4>
          <p>{{Exam.Active?'Si':'No'}}</p>
          <h4>Enviar calificación a estudiante</h4>
          <p>{{Exam.SendQualification?'Si':'No'}}</p>
          <h4>Respetar orden de preguntas</h4>
          <p>{{Exam.RespectQuestionOrder?'Si':'No'}}</p>
          <h4>Tiene Lectura</h4>
          <p>{{Exam.HasReading?'Si':'No'}}</p>
        </tab>
        <tab heading='Descripción'>
          <h4>Descripción</h4>
          <p>{{Exam.Description}}</p>
        </tab>
        <tab *ngIf="Exam.HasReading" heading='Detalles de lecturas'>
          <h4>Nombre</h4>
          <p>{{Reading.Name}}</p>
          <h4>Tiempo de lectura</h4>
          <p>{{Reading.ReadingTime}}</p>
          <h4>Descripción</h4>
          <p>{{Reading.Description}}</p>
        </tab>
        <tab *ngIf="Exam.HasReading" heading='Lectura'>
          <h4>Lectura</h4>
          <p>{{Reading.Reading}}</p>
        </tab>
      </tabset>
    </div>
    <div class="form-group text-center">
      <button class="btn btn-success" type="button" [routerLink]="['/exams']">Regresar</button>
    </div>
  </div>
</div>