<div class="container mt-4">
  <div class="row">
    <div class="col-sm-4">
      <div class="card">
        <img class="card-img-top img-thumbnail" src="https://www.vhv.rs/dpng/d/120-1200250_aws-simple-icons-non-service-specific-user-profile.png" alt="{{user.Name}} {{user.LastName}}">
        <div class="card-body">
          <div>
            <strong>Localidad: </strong>
            <p>{{user.City}}, {{user.Country}}</p>
          </div>
          <div>
            <strong>Edad: </strong>
            <p>{{getAge()}}</p>
          </div>
          <div>
            <strong>Fecha de registro: </strong>
            <p>{{user.RegistrationDate | date:'MM/dd/yyyy'}}</p>
          </div>
        </div>
        <div class="card-footer">
          <!-- <div class="btn-group d-flex">
            <button class="btn btn-primary w-100">Like</button>
            <button class="btn btn-success w-100">message</button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-sm-8">
        <tabset class="member-tabset">
          <tab heading='Información Personal'>
            <h4>Nombre</h4>
            <p>{{user.Name}}</p>
            <h4>Apellidos</h4>
            <p>{{user.LastName}}</p>
            <h4>Email</h4>
            <p>{{user.Email}}</p>
            <h4>Profesión</h4>
            <p>{{user.Profession}}</p>
            <h4>Fecha de nacimiento</h4>
            <p>{{user.DateOfBirth | date:'MM/dd/yyyy'}}</p>
            <h4>País</h4>
            <p>{{user.Country}}</p>
            <h4>Ciudad</h4>
            <p>{{user.City}}</p>
            <h4>Género</h4>
            <p>{{user.Gender}}</p>
            <h4>Grado académico</h4>
            <p>{{user.AcademicDegree}}</p>
          </tab>
          <tab heading='Intereses'>
            <h4>Intereses</h4>
            <p>{{user.Subject}}</p>
          </tab>
          <tab heading='Asignar examen'>
            <app-exam-assign [idUser]="user.idUser"></app-exam-assign>
          </tab>
          <tab heading='Asignar curso'>
            <app-course-assign [idUser]="user.idUser"></app-course-assign>
          </tab>
        </tabset>
    </div>
  </div>
  <div class="form-group text-center">
    <button class="btn btn-success" type="button" [routerLink]="['/users']">Regresar</button>
  </div>
</div>
