import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { Exam } from 'src/app/_models/Exam';
import { Level } from 'src/app/_models/Level';
import { ReadingModel } from 'src/app/_models/ReadingModel';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-exam-edit',
  templateUrl: './exam-edit.component.html',
  styleUrls: ['./exam-edit.component.scss']
})
export class ExamEditComponent implements OnInit {
  updateForm: FormGroup;
  exam: Exam;
  Levels: Level[];
  Classifications: Classification[];
  Readings: ReadingModel[];
  ShowReadings: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {

    this.route.data.subscribe(data => {
      this.exam = data.exam;
    });

    this.route.data.subscribe(data => {
      this.Levels = data.Levels;
    });

    this.createUpdateForm();

    this.onChangeLevel(this.exam.IdLevel);
    this.initializeClassifications();
    //console.log(JSON.stringify(this.updateForm));
    if (this.exam.HasReading) {
      this.onChangeHasReading();
    }
  }

  createUpdateForm() {
    this.updateForm = this.fb.group({
      Active: [this.exam.Active , Validators.required],
      Name: [this.exam.Name , Validators.required],
      Description: [this.exam.Description, Validators.required],
      idLevel: [this.exam.IdLevel, Validators.required],
      QuestionTime: [this.exam.QuestionTime , Validators.required],
      QuestionNumber: [this.exam.QuestionNumber, Validators.required],
      Price: [this.exam.Price, Validators.required],
      SendQualification: [this.exam.SendQualification],
      RespectQuestionOrder: [this.exam.RespectQuestionOrder],
      HasReading: [this.exam.HasReading],
      idReading: [this.exam.idReading],
      Classifications: this.fb.array([])
    });
    
  }

  initializeClassifications() {
    this.exam.Classifications.forEach(classification => {
      const Classifications = this.updateForm.get('Classifications') as FormArray;
      Classifications.push(this.createClassficationInitilize(classification.idClassification));
    });
   
  }

  addClassification() {
    const Classifications = this.updateForm.get('Classifications') as FormArray;
    if (Classifications.length <= this.Classifications.length - 1 )
    {
      Classifications.push(this.createClassficationsFrom());
      this.updateForm.markAsDirty();
    }else
    {
      this.alertify.warning("Solo existen classificaciones " + this.Classifications.length + " para este nivel.");
    }
  }

  removeClassification(id: number) {
    const Classifications = this.updateForm.get('Classifications') as FormArray;
    Classifications.removeAt(id);
    this.updateForm.markAsDirty();
  }

  createClassficationInitilize(id: number): FormGroup{
    return new FormGroup ({
      idExams: new FormControl(this.exam.IdExams, Validators.required),
      idClassification: new FormControl(id, Validators.required)
    });
   }

  createClassficationsFrom(): FormGroup{
    return new FormGroup ({
      idExams: new FormControl(this.exam.IdExams),
      idClassification: new FormControl('', Validators.required)
    });
   }

  public getFormArray(): FormArray{
    var form = this.updateForm.get('Classifications') as FormArray;
    return form;
  }

  onChangeLevel(value){
    this.examService.getClassifications(value).subscribe((classifications: Classification[]) => {
      this.Classifications = classifications;
    }, error => {
      this.alertify.error(error);
    });
  }
  
  onChangeHasReading(){
    let value = this.updateForm.get('HasReading').value;
    if (value){
      this.examService.getReadings().subscribe((readings: ReadingModel[]) => {
        this.Readings = readings;
        this.ShowReadings = true;
      }, error => {
        this.alertify.error(error);
      });
    }
    else
    {
      this.exam.idReading = null;
      this.ShowReadings = false;
      this.Readings = null;
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  updateExam(){
    Object.assign(this.exam, this.updateForm.value);
    this.examService.UpdateExam(this.exam).subscribe(() => {
      this.alertify.success('Examen Actualizado');
      this.router.navigate(['/exams']);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
    });
    this.updateForm.reset(this.updateForm.value);
  }

}
