import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  model: any = {};
  toogleMenu: boolean;
  hideActions: boolean;
  constructor(public authService: AuthService, private userService: UserService, private alertify: AlertifyService, private router: Router ) { }
  

  ngOnInit() {
    if (this.authService.logguedIn()) {
      this.hasRol();
    }
  }

  hasRol() {
    this.userService.hasRol(this.getUserId()).subscribe((hide: boolean) => {
      this.hideActions = hide;
    });
  }

  login() {
    this.authService.login(this.model).subscribe(next => {
      this.hasRol();
      this.alertify.success('logued');
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.router.navigate(['/exams']);
    });
  }

  loggedIn() {
    return this.authService.logguedIn();
  }

  logout(){
    localStorage.removeItem('token');
    this.alertify.message('loged out');
    this.router.navigate(['/home']);
  }

  getUserId(): number {
    return this.authService.getUserId();
  }

  toggle() {
    let selector = '.navbar-collapse';
    document.querySelector(selector).classList.toggle('collapse');
    this.toogleMenu = true;
  }

}
