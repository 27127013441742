<div class="container mt-4 mb-5" style="width: 150%; padding: -10%;">
  <h3 class="mt-3">Gracias por adquirir un examen con nostros</h3>
  <!-- <h5 class="mt-5">Descripción</h5> -->
  <p class="mt-1 hihgligth">Tiene 5 dias para presentar su examen apartir de hoy, pasando los 5 dias se desactiva.</p>
  <div class="row d-felx justify-content-around">
    <div class="col-8">
      <div class="d-felx justify-content-around">
        <button class="btn btn-outline-secondary" type="button" [routerLink]="['/exams']">Comprar otro examen</button>
        <button class="btn btn-outline-success ml-3" [routerLink]="['/myexams/']" routerLinkActive="router-link-active" >ir a mis examenes <i class="fa fa-pencil-square-o"></i></button>
      </div>
    </div>
  </div>
</div>
