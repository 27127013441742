<div class="text-center">
  <div class="center_div">

      <form [formGroup]="QuestionsListFrom" (ngSubmit)="registerQuestions()">
        <h2 class="text-center text-primary">Registrar Preguntas</h2>
        <hr>
        
        <div class="form-group ml-2" *ngFor="let Question of getFormArray('Questions').controls | keyvalue: keyAscOrder; let i = index" formArrayName="Questions">
          <div [formGroupName]="Question.key">
            <div class="btn-group d-flex">
              <div class="form-group">
                <p>{{(parseInt(Question.key)+1)+ '.'}}</p>
              </div>
              
              <div class="form-group w-100 ml-2 mr-2">
                <input type="text" [ngClass]="{ 'is-invalid': 
                QuestionsListFrom.get('Questions').get(Question.key).get('QuestionText').errors 
                && QuestionsListFrom.get('Questions').get(Question.key).get('QuestionText').touched }"
                       class="form-control" [formControlName]="'QuestionText'" placeholder="Pregunta" />
                <div class="invalid-feedback">Porfavor ingrese la pregunta</div>
              </div>
      
              <div class="form-group w-25">
                <input type="text" (keypress)="onlyNumberKey($event)" (input)="addComponent($event.target.value, (i+1))" maxlength="2"
                      [ngClass]="{'is-invalid': 
                      QuestionsListFrom.get('Questions').get(Question.key).get('OptionsNumber').errors 
                      && QuestionsListFrom.get('Questions').get(Question.key).get('OptionsNumber').touched }"
                      class="form-control" [formControlName]="'OptionsNumber'" placeholder="Rptas"/>
                <div class="invalid-feedback">Agregue número de respuetas</div>
              </div>
      
            </div>
      
            <div class="form-group" *ngFor="let control of getFormGroupControls('Answers', Question.key).controls | keyvalue: keyAscOrder; let j = index" [formArrayName]="'Answers'">
              <div class="btn-group d-flex" [formGroupName]="control.key">
                <div class="form-group answer ml-5 mr-2">
                  <input type="text" [ngClass]="{ 'is-invalid': 
                  QuestionsListFrom.get('Questions').get(Question.key).get('Answers').get(control.key).get('Answer').errors &&
                  QuestionsListFrom.get('Questions').get(Question.key).get('Answers').get(control.key).get('Answer').touched }" 
                        class="form-control" [formControlName]="'Answer'"
                        placeholder="{{ 'Respuesta ' + (j+1) }}"/>
                  <div class="invalid-feedback">Porfavor ingrese respuesta</div>
                </div>
      
                <div class="form-group mt-2 ml-4"><input class="form-check-input" type="checkbox" [formControlName]="'IsCorrect'" /></div>
              </div>
            </div>
          </div>
        </div>
      
        <div class="form-group text-center">
          <button class="btn btn-success" type="button" (click)="addQuestionsFormGruop()">Agregar Pregunta</button>
        </div>
      
        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!QuestionsListFrom.valid" type="submit">Register</button>
          <button class="btn btn-default" type="button" (click)="cancel()">Cancelar</button>
        </div>
      </form>
      
  </div>
</div>


