import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReadingModel } from 'src/app/_models/ReadingModel';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-reading-add',
  templateUrl: './reading-add.component.html',
  styleUrls: ['./reading-add.component.css']
})
export class ReadingAddComponent implements OnInit {
  registerForm: FormGroup;
  reading: ReadingModel;
  
  constructor(private router: Router, private route: ActivatedRoute,
    private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      Name: ['', Validators.required],
      Reading: ['', Validators.required],
      ReadingTime: ['', Validators.required],
      Description: ['', Validators.required]
    });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  registerReading(){
    if (this.registerForm.valid){
      this.reading = Object.assign({}, this.registerForm.value);
      this.examService.insertReading(this.reading).subscribe(() => {
          this.alertify.success('Lectura Agregada');
          this.router.navigate(['/readings']);
        }, error => {
          this.alertify.error(error);
        }, () => {      
        });
    }
  }
}
