import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/_models/User';

@Component({
  selector: 'tbody[app-user-row]',
  styleUrls: ['./user-row.component.scss'],
  template: `
  <tr *ngFor="let user of users">
    <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{user.idUser}}</th>
    <td class="p-0 align-middle">{{user.Name}} {{user.LastName}}</td>
    <td class="p-0 align-middle text-center">{{user.City}}, {{user.Country}}</td>
    <td class="p-0 align-middle text-center">{{user.Gender}}</td>
    <td class="p-0 align-middle d-none d-lg-table-cell">{{user.Email}}</td>
    <td class="p-0 align-middle">
      <div class="btn-group d-flex">
        <button class="btn btn-outline-primary w-100" [routerLink]="['/users/detail/', user.idUser]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
        <button class="btn btn-outline-info w-100" [routerLink]="['/users/detail/', user.idUser]" routerLinkActive="router-link-active" ><i class="fa fa-info"></i></button>
      </div> 
    </td>
  </tr>
  `
})
export class UserRowComponent implements OnInit {
  @Input() users: User[];
  constructor() { }

  ngOnInit() {
  }

}
