import { Component, Input, OnInit } from '@angular/core';
import { Exam } from 'src/app/_models/Exam';

@Component({
  selector: 'tbody[app-exam-row]',
  styleUrls: ['./exam-row.component.css'],
  template: `
  <tr *ngFor="let exam of exams" class="p-0">
    <th scope="row" class="p-0 align-middle text-center">{{exam.idExams}}</th>
    <td class="p-0 align-middle">{{exam.Name}}</td>
    <td class="p-0 align-middle text-center">{{exam.QuestionNumber}}</td>
    <td class="p-0 align-middle text-center">{{exam.LevelName}}</td>
    <td class="p-0 align-middle text-center">{{exam.ClassificationName}}</td>
    <td class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=exam.Active disabled></td>
    <td class="p-0">
      <div class="btn-group d-flex">
        <button class="btn btn-outline-primary w-100" [routerLink]="['/payment']" [state]="{Price: 10}" routerLinkActive="router-link-active" ><i class="fa fa-paypal"></i></button>
        <button class="btn btn-outline-primary w-100" [routerLink]="['/exams/edit/', exam.idExams]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
        <button class="btn btn-outline-info w-100" [routerLink]="['/exams/detail/', exam.idExams]" routerLinkActive="router-link-active" ><i class="fa fa-info"></i></button>
      </div>
    </td>
  </tr>
  `
})
export class ExamRowComponent implements OnInit {
  @Input() exams: Exam[];
  constructor() { }

  ngOnInit() {
  }

}
