import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/_models/Course';

@Component({
  selector: 'app-list-userCourses',
  templateUrl: './list-userCourses.component.html',
  styleUrls: ['./list-userCourses.component.css']
})
export class ListUserCoursesComponent implements OnInit {
  Courses: Course[];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.Courses = data.userCourses;
    });
  }

}
