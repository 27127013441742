<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/home']">Exams</a>
    <div *ngIf="!loggedIn()" class="d-lg-none" id="login-nav-mob" >
      <button class="btn btn-success my-2 my-sm-0" [routerLink]="['/login']">Inicia sesion</button>
    </div>
    <button class="navbar-toggler" type="button" (click)="toggle()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul *ngIf="loggedIn()" class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/home']">Home<span class="sr-only">(current)</span></a>
        </li>
        <li *ngIf="hideActions" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/users']">Usuarios</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/courses']">Cursos</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/exams']">Exams</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/myexams']">My Exams</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/mycourses']">My Courses</a>
        </li>
        <li *ngIf="hideActions" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/levels']">Niveles</a>
        </li>
        <li *ngIf="hideActions" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/classifications']">Clasificaciones</a>
        </li>
        <li *ngIf="hideActions" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/readings']">Lecturas</a>
        </li>
      </ul>

      <div *ngIf="loggedIn()" class="dropdown" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle >
          Welcome {{authService.decodedToken?.unique_name | titlecase}}
        </a>
  
        <div class="dropdown-menu mt-3" *dropdownMenu>
          <a class="dropdown-item" [routerLink]="['/user/edit']"><i class="fa fa-user"></i> Edit Profile</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block">
      <form *ngIf="!loggedIn()" #loginForm="ngForm" class="form-inline mt-2 mt-md-0" (ngSubmit)="login()">
        <input class="form-control mr-sm-2" type="text" name="username" placeholder="Username" required [(ngModel)]="model.username"l>
        <input class="form-control mr-sm-2" type="password" name="UserPassWord" placeholder="Password" required [(ngModel)]="model.UserPassWord">
        <button [disabled]="!loginForm.valid" id="login-btn" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
      </form>
    </div>  
  </div>
</nav>