<div class="container mt-4">
  <div class="row">
    <div class="col-sm-4">
      <h1>Mi perfil</h1>
    </div>
    <div class="col-sm-8">
      <div *ngIf="editForm.dirty" class="alert alert-info">
        <strong>Informacion: </strong> Has realizado cambios en tu perfil. todos los cambios no guardados se perderan!
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="card">
        <img class="card-img-top img-thumbnail" src="https://www.vhv.rs/dpng/d/120-1200250_aws-simple-icons-non-service-specific-user-profile.png" alt="{{user.Name}} {{user.LastName}}">
        <div class="card-body">
          <div>
            <strong>Location: </strong>
            <p>{{user.City}}, {{user.Country}}</p>
          </div>
          <div>
            <strong>Edad: </strong>
            <p>{{getAge()}}</p>
          </div>
          <div>
            <strong>Registro: </strong>
            <p>{{user.RegistrationDate | date:'MM/dd/yyyy'}}</p>
          </div>
        </div>
        <div class="card-footer"> 
            <button [disabled]="!editForm.dirty || !editForm.valid" form="editForm" class="btn btn-success btn-block">Guardar cambios</button>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
        <tabset class="member-tabset">
          <tab heading='Editar informacion Personal'>
            <form #editForm="ngForm" id="editForm" (ngSubmit)="updateUser()">
              <h4>Nombre(s)</h4>
              <input type="text" class="form-control" name="Name" [(ngModel)]="user.Name">
              <h4>Apellido(s)</h4>
              <input type="text" class="form-control" name="LastName" [(ngModel)]="user.LastName">
              <h4>Email</h4>
              <input type="text" class="form-control" name="Email" [(ngModel)]="user.Email">
              <h4>Profesión</h4>
              <input type="text" class="form-control" name="Profession" [(ngModel)]="user.Profession">
              <h4>Fecha de nacimiento</h4>
              <p>{{user.DateOfBirth | date:'MM/dd/yyyy'}}</p>
              <h4>País</h4>
              <input type="text" class="form-control" name="Country" [(ngModel)]="user.Country">
              <h4>Ciudad</h4>
              <input type="text" class="form-control" name="City" [(ngModel)]="user.City">
              <h4>Género</h4>
              <p>{{user.Gender}}</p>
              <h4>Grado académico</h4>
              <input type="text" class="form-control" name="AcademicDegree" [(ngModel)]="user.AcademicDegree">
            </form>
          </tab>
          <tab heading='Intereses'>
            <h4>Intereses</h4>
            <p>{{user.Subject}}</p>
          </tab>
        </tabset>
    </div>
  </div>
</div>
