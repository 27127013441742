import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Answer } from 'src/app/_models/Answer';
import { Question } from 'src/app/_models/Question';
import { QuestionWithAnswers } from 'src/app/_models/QuestionWithAnswers';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss']
})
export class QuestionEditComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  question: Question;
  anwers: Answer[] = [];
  questionWansers: QuestionWithAnswers;
  constructor(private router: Router, private examService: ExamService,
              private route: ActivatedRoute, private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.questionWansers = data['Question'];
    });

    this.question =  this.questionWansers.Question;
    this.anwers = this.questionWansers.Answers;
  }

  updateQuestion(){
    this.anwers.forEach((value, index) => {
      if (value.Answer == '') {
        // console.log(index);
        this.alertify.error(`La respuesta ${index + 1} esta vacia, favor de ingresarla`);
        return;
      }
    });
    this.questionWansers.Answers = this.anwers;
    this.questionWansers.Question = this.question;
    this.examService.UpdateQuestion(this.questionWansers).subscribe(() => {
      this.alertify.success('Pregunta Actualizada');
      this.router.navigate(['/classifications/edit/questions/' + this.question.idClassification]);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
       // this.authService.login(this.user);
    });
  }

  addAnswer(): void{
    this.editForm.control.markAsTouched();
    this.editForm.control.markAsDirty();
    this.question.OptionsNumber = this.question.OptionsNumber + 1;
    this.questionWansers.Answers.push( { idAnswer: 0, Answer: '', IsCorrect: false, idQuestion: this.question.idQuestion });
  }

}
