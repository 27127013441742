import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { InsertQuestionsRequest } from 'src/app/_models/InserQuestionsRequest';
import { Question } from 'src/app/_models/Question';
import { QuestionWithAnswers } from 'src/app/_models/QuestionWithAnswers';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-questions-add',
  templateUrl: './questions-add.component.html',
  styleUrls: ['./questions-add.component.scss']
})

export class QuestionsAddComponent implements OnInit {
  @Input() Classification: Classification = null;
  @Output() cancelRegister = new EventEmitter();

  QuestionsListFrom: FormGroup;
  fields = [];
  NoAnswerXQuestion = [];
  QuestionCount = 0;
  QuestionsList: QuestionWithAnswers[] = [];
  model: InsertQuestionsRequest = { QuestionsList: this.QuestionsList };

  constructor(private router: Router, private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.QuestionsListFrom = this.fb.group({
      Questions: this.fb.array([])
    });

    this.addQuestionsFormGruop();
  }

  public createQuestionFormGroup(): FormGroup {
    this.NoAnswerXQuestion.push(0);
    return new FormGroup ({
      QuestionText: new FormControl('', Validators.required),
      OptionsNumber: new FormControl('', Validators.required),
      Answers: this.fb.array([])
    });
  }

  public addQuestionsFormGruop() {
    const questions = this.QuestionsListFrom.get('Questions') as FormArray;
    questions.push(this.createQuestionFormGroup());
  }

  public createAnswerFormGroup(): FormGroup{
    return new  FormGroup({
      Answer: new FormControl('', Validators.required),
      IsCorrect: new FormControl(false)
    });
  }

  public addAnswerFormGroup(idQuestion: number) {
    const answers = this.QuestionsListFrom.get('Questions').get(String(idQuestion-1)).get('Answers') as FormArray
    answers.push(this.createAnswerFormGroup())
  }

  public removeAnswer(id: number, idQuestion: number){
    let idx: number = id - 1;
    const answers = this.QuestionsListFrom.get('Questions').get(String(idQuestion-1)).get('Answers') as FormArray
    answers.removeAt(idx);
  }

  public getFormArray(name: string): FormArray{
    var form = this.QuestionsListFrom.get(name) as FormArray;
    return form;
  }

  public getFormGroupControls(name: string, Parent: string): FormGroup{
    var form = this.QuestionsListFrom.get('Questions').get(Parent).get(name) as FormGroup;
    return form;
  }

  onlyNumberKey(event): boolean {
    if ((event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57)
    {
      return true;
    }
    return false;
  }

  addComponent(value, idQuestion: number): void {
    if (value != ''){
      if (value < this.NoAnswerXQuestion[idQuestion-1]){
        this.removeControls(value, idQuestion);
      }
      value++;
      for(let i = 1; i < value; i++)
      {
        let key = 'q' + idQuestion + 'a' + i;
        var existe = this.fields.findIndex(x => x.key == key)
        if (existe == -1){
          this.addAnswerFormGroup(idQuestion);
          this.fields.push({key: key, id: 'Answer' + i, label: 'Respuesta ' + i, idCheck: 'IsCorrect'+i});
        }
      }
      this.NoAnswerXQuestion[idQuestion - 1] = value - 1;
    }
  }

  removeControls(value, idQuestion: number){
    let max: number = this.NoAnswerXQuestion[idQuestion - 1];
    value;
    for(let i = max; i > value; i--){
      let idx: number = i;
      let key = 'q' + idQuestion + 'a' + idx;
      this.fields.splice(this.fields.findIndex(x => x.key == key), 1);
      this.removeAnswer(idx, idQuestion);
    }
  }

  registerQuestions(){
    if (this.QuestionsListFrom.valid){ 
      if (this.verifySelectedAnswers()){
        Object.keys(this.getFormArray('Questions').controls).forEach(frmGroup => {
          var questioninfo = this.QuestionsListFrom.get('Questions').get(frmGroup) as FormGroup;
          let q: Question = {
            Active: true,
            OptionsNumber: questioninfo.get('OptionsNumber').value,
            Question: questioninfo.get('QuestionText').value,
            idClassification: this.Classification.idClassification,
            idQuestion: 0
          };

          let obj: QuestionWithAnswers = {Question: q, Answers: questioninfo.get('Answers').value};

          this.QuestionsList.push(obj);
        });

        //console.log(JSON.stringify(this.model));

        this.examService.insertQuestions(this.model).subscribe(() => {
          this.alertify.success('Preguntas Agregadas');
          this.router.navigateByUrl('', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/classifications/edit/questions/' + this.Classification.idClassification]);
          });
        }, error => {
          this.alertify.error(error);
        }, () => {
           // this.authService.login(this.user);
        });
        // console.log(this.model);
      }
    }
  }

  verifySelectedAnswers(): Boolean{
    let allQunsHasIsCorrectMark: boolean =true;
    Object.keys(this.getFormArray('Questions').controls).forEach(frmGroup => {
      let IsCorrectCount: number = 0;
      var question = this.QuestionsListFrom.get('Questions').get(frmGroup) as FormGroup;
      Object.keys(question.get('Answers').value).forEach(answers => {
        var answer = this.QuestionsListFrom.get('Questions').get(frmGroup).get('Answers').get(answers) as FormGroup;
        if (answer.get('IsCorrect').value) {
          IsCorrectCount++;
        }
        //console.log(answer.get('IsCorrect').value);
      });
      if (IsCorrectCount == 0) {
        this.alertify.error('No se marco niguna respuesta correcta para la pregunta:  ' + (this.parseInt(frmGroup)+1));
        allQunsHasIsCorrectMark = false;
      }
      //console.log('Pregunta: ' + (this.parseInt(frmGroup)+1) + ' IsCorrectCount: ' + IsCorrectCount);
    });
    return allQunsHasIsCorrectMark;
  }

  cancel() {
    this.cancelRegister.emit(false);
    this.alertify.message('cancelado');
  }

  keyAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return parseInt(a.key) < parseInt(b.key) ? -1 : (parseInt(b.key) > parseInt(a.key) ? 1 : 0);
  }

  parseInt(value: string): number{
    return Number(value);
  }
}
