import { Routes } from '@angular/router';
import { ExamAddComponent } from './Components/exam-add/exam-add.component';
import { HomeComponent } from './Components/home/home.component';
import { LevelAddComponent } from './Components/level-add/level-add.component';
import { ListClassificationsComponent } from './Components/list-classifications/list-classifications.component';
import { ClassificationsAddComponent } from './Components/classifications-add/classifications-add.component';
import { ListLevelsComponent } from './Components/list-levels/list-levels.component';
import { ListUsersComponent } from './Components/list-users/list-users.component';
import { UserDetailComponent } from './Components/user-detail/user-detail.component';
import { UserEditComponent } from './Components/User-edit/User-edit.component';
import { ExamsComponent } from './Exams/Exams.component';
import { AuthGuard } from './_guards/auth.guard';
import { ListClassificationsResolver } from './_resolvers/list-Classifications.resolver';
import { ListExamsResolver } from './_resolvers/list-exams.resolver';
import { ListLevelsResolver } from './_resolvers/list-levels.resolver';
import { ListUsersResolver } from './_resolvers/list-users.resolver';
import { UserDetailResolver } from './_resolvers/user-detail.resolver';
import { UserEditResolver } from './_resolvers/user-edit.resolver';
import { ClassificationDetailResolver } from './_resolvers/classification-detail.resolver';
import { ClassificationDetailComponent } from './Components/classification-detail/classification-detail.component';
import { ClassificationEditComponent } from './Components/classification-edit/classification-edit.component';
import { ListQuestionsComponent } from './Components/list-questions/list-questions.component';
import { LevelDetailComponent } from './Components/level-detail/level-detail.component';
import { LevelEditComponent } from './Components/level-edit/level-edit.component';
import { LevelDetailResolver } from './_resolvers/level-detail.resolver';
import { ExamDetailComponent } from './Components/exam-detail/exam-detail.component';
import { ExamEditComponent } from './Components/exam-edit/exam-edit.component';
import { ExamDetailResolver } from './_resolvers/exam-detail.resolver';
import { ListQuestionsResolver } from './_resolvers/list-questions.resolver';
import { ExamTopresentComponent } from './Components/exam-topresent/exam-topresent.component';
import { listUserExamsResolver } from './_resolvers/list-userExams.resolver';
import { ListUserExamsComponent } from './Components/list-userExams/list-userExams.component';
import { ExamToPresentDetailResolver } from './_resolvers/examtopresent-detail.resolver';
import { QuestionEditComponent } from './Components/question-edit/question-edit.component';
import { QuestionDetailResolver } from './_resolvers/question-detail.resolver';
import { PaypalComponent } from './Components/paypal/paypal.component';
import { ExamPurchasedsuccessComponent } from './Components/exam-purchasedsuccess/exam-purchasedsuccess.component';
import { ListCoursesResolver } from './_resolvers/list-courses.resolver';
import { ListCoursesComponent } from './Components/list-courses/list-courses.component';
import { ListUserCoursesComponent } from './Components/list-userCourses/list-userCourses.component';
import { listUserCoursesResolver } from './_resolvers/list-userCourses.resolver';
import { CourseDetailResolver } from './_resolvers/course-detail.resolver';
import { CourseDetailComponent } from './Components/course-detail/course-detail.component';
import { CourseEditComponent } from './Components/course-edit/course-edit.component';
import { CourseAddComponent } from './Components/course-add/course-add.component';
import { ListReadingsComponent } from './Components/list-readings/list-readings.component';
import { ListReadingsResolver } from './_resolvers/list-readings.resolver';
import { ReadingDetailResolver } from './_resolvers/reading-detail.resolver';
import { ReadingDetailComponent } from './Components/reading-detail/reading-detail.component';
import { ReadingEditComponent } from './Components/reading-edit/reading-edit.component';
import { ReadingAddComponent } from './Components/reading-add/reading-add.component';
import { LoginMobileComponent } from './Components/login-mobile/login-mobile.component';

export const appRoutes: Routes = [
    {   
        path: '', 
        children: [
            { path: '', component: HomeComponent},
            { path: 'login', component: LoginMobileComponent}
        ] 
     },
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            { path: '', component: HomeComponent},
            { path: 'payment', component: PaypalComponent},
            { path: 'paymentsuccess', component: ExamPurchasedsuccessComponent},
            { path: 'courses', component: ListCoursesComponent, resolve: {Courses: ListCoursesResolver}},
            { path: 'courses/add', component: CourseAddComponent},
            { path: 'courses/detail/:id', component: CourseDetailComponent, resolve: {Course: CourseDetailResolver}},
            { path: 'courses/edit/:id', component: CourseEditComponent, resolve: {Course: CourseDetailResolver}},
            { path: 'mycourses', component: ListUserCoursesComponent, resolve: {userCourses: listUserCoursesResolver}},
            { path: 'exams', component: ExamsComponent, resolve: {Exams: ListExamsResolver}},
            { path: 'myexams', component: ListUserExamsComponent, resolve: {userExams: listUserExamsResolver}},
            { path: 'presentexam/:id', component: ExamTopresentComponent, resolve: {ExamToPresent: ExamToPresentDetailResolver}},
            { path: 'exams/add', component: ExamAddComponent, resolve: {Levels: ListLevelsResolver}},
            { path: 'exams/detail/:id', component: ExamDetailComponent, resolve: {exam: ExamDetailResolver}},
            { path: 'exams/edit/:id', component: ExamEditComponent, resolve: {Levels: ListLevelsResolver, exam: ExamDetailResolver}},
            { path: 'levels', component: ListLevelsComponent, resolve: {Levels: ListLevelsResolver}},
            { path: 'classifications/edit/questions/:id', component: ListQuestionsComponent, resolve: {questions: ListQuestionsResolver, classification: ClassificationDetailResolver}},
            { path: 'levels/add', component: LevelAddComponent},
            { path: 'levels/detail/:id', component: LevelDetailComponent, resolve: {level: LevelDetailResolver}},
            { path: 'levels/edit/:id', component: LevelEditComponent, resolve: {level: LevelDetailResolver}},
            { path: 'user/edit', component: UserEditComponent, resolve: {user: UserEditResolver}},
            { path: 'classifications', component: ListClassificationsComponent, resolve: {classifications: ListClassificationsResolver}},
            { path: 'classifications/add', component: ClassificationsAddComponent, resolve: {Levels: ListLevelsResolver}},
            { path: 'classifications/detail/:id', component: ClassificationDetailComponent, resolve: {classification: ClassificationDetailResolver}},
            { path: 'classifications/edit/:id', component: ClassificationEditComponent, resolve: {Levels: ListLevelsResolver, classification: ClassificationDetailResolver}},
            { path: 'question/edit/:id', component: QuestionEditComponent, resolve: {Question: QuestionDetailResolver}},
            { path: 'users/detail/:id', component: UserDetailComponent, resolve: {user: UserDetailResolver}},
            { path: 'users', component: ListUsersComponent, resolve: {users: ListUsersResolver}},
            { path: 'readings', component: ListReadingsComponent, resolve: {readings: ListReadingsResolver}},
            { path: 'readings/add', component: ReadingAddComponent },
            { path: 'readings/detail/:id', component: ReadingDetailComponent, resolve: {reading: ReadingDetailResolver}},
            { path: 'readings/edit/:id', component: ReadingEditComponent, resolve: {reading: ReadingDetailResolver}}
        ]
    },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
