import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Exam } from 'src/app/_models/Exam';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-exam-topresentdetail',
  templateUrl: './exam-topresentdetail.component.html',
  styleUrls: ['./exam-topresentdetail.component.css']
})
export class ExamTopresentdetailComponent implements OnInit {
  @Output() PresentExamMode = new EventEmitter();
  @Input() idExam: number;
  @Input() idUserExams: number;
  exam: Exam = { Name: '', 
                 Active: true, 
                 Description: '', 
                 IdExams: 0, 
                 IdLevel: 0, 
                 Price: 0, 
                 QuestionNumber: 0, 
                 QuestionTime: 0, 
                 HasReading: false, 
                 SendQualification: false, 
                 RespectQuestionOrder: false,
                 idReading: 0, 
                 Classifications: null
                };
                
  constructor(private alertify: AlertifyService, private examService: ExamService) { }

  ngOnInit() {
    this.loadExamInfo();
  }

  loadExamInfo() {
    this.examService.getExam(this.idExam).subscribe((exam: Exam) => {
      this.exam = exam;
    }, error => {
      this.alertify.error(error);
    });
  }
  cancel() {
    this.PresentExamMode.emit(false);
    this.alertify.message('cancelled');
  }
}
