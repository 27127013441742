import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { Level } from 'src/app/_models/Level';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-classifications-add',
  templateUrl: './classifications-add.component.html',
  styleUrls: ['./classifications-add.component.css']
})
export class ClassificationsAddComponent implements OnInit {
  registerForm: FormGroup;
  Levels: Level[];
  classifcation: Classification;

  constructor(private router: Router, private route: ActivatedRoute,
              private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {
    this.createRegisterForm();

    this.route.data.subscribe(data => {
      this.Levels = data['Levels'];
    });
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      ClassificationName: ['', Validators.required],
      ClassificationDescription: ['', Validators.required],
      idLevel: ['', Validators.required],
      ClassificationOrder: ['', Validators.required]
    });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  registerClassification(){
    if (this.registerForm.valid){
      this.classifcation = Object.assign({}, this.registerForm.value);
      this.classifcation.Active = true;
      this.examService.insertClassification(this.classifcation).subscribe(() => {
          this.alertify.success('Classificacion Agregada');
          this.router.navigate(['/classifications']);
        }, error => {
          this.alertify.error(error);
        }, () => {
           //this.authService.login(this.user);
        });
    }
    //console.log(this.registerForm.value);
  }
}
