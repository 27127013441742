<div class="text-center mt-5">
  <div class="center_div">
    <div class="container mt-4 text-left">
      <tabset class="member-tabset">
        <tab heading='Información de Clasificación'>
          <h4>Nombre</h4>
          <p>{{Level.LevelName}}</p>
          <h4>Activo</h4>
          <p>{{Level.Active?'Si':'No'}}</p>
        </tab>
        <tab heading='Descipción'>
          <h4>Descripción</h4>
          <p>{{Level.LevelDescription}}</p>
        </tab>
      </tabset>
    </div>
    <div class="form-group text-center">
      <button class="btn btn-success" type="button" [routerLink]="['/levels']">Regresar</button>
    </div>
  </div>
</div>