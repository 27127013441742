import { Component, OnInit } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { JwtHelperService} from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ExamsApp-Front';
  jwtHelper = new JwtHelperService();

  constructor(private authService: AuthService) {

  }
  ngOnInit(): void {
     const token = localStorage.getItem('token');
     if (token) {
        this.authService.decodedToken = this.jwtHelper.decodeToken(token);
     }

     if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

}
