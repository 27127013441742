<div class="container mt-4">
  <div class="row">
    <div class="col-sm-2">
      <!-- cosos para buscar -->
      <div class="card-footer mb-3">
        <div class="btn-group d-flex">
          <button class="btn btn-success w-100" [routerLink]="['/classifications/add']" routerLinkActive="router-link-active">Agregar</button>
        </div>
      </div>
    </div>
    <div class="col sm-2">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Id</th>
            <th scope="col" class="p-1 align-middle text-center">Nombre</th>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Preguntas</th>
            <th scope="col" class="p-1 align-middle text-center d-none d-lg-table-cell">Descripción</th>
            <th scope="col" class="p-1 align-middle text-center">Activo</th>
          </tr>
        </thead>
        
        <tr *ngFor="let classification of classifications" class="p-0">
          <th scope="row" class="p-0 align-middle text-center d-none d-lg-table-cell">{{classification.idClassification}}</th>
          <td class="p-0 align-middle">{{classification.ClassificationName}}</td>
          <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{classification.QuestionCount}}</td>
          <td class="p-0 align-middle text-center d-none d-lg-table-cell">{{classification.ClassificationDescription}}</td>
          <td class="p-0 align-middle text-center"><input class="m-0 form-check-input" type="checkbox" [checked]=classification.Active disabled></td>
          <td class="p-0 align-middle">
            <div class="btn-group d-flex">
              <button class="btn btn-outline-primary w-100" [routerLink]="['/classifications/edit/questions/', classification.idClassification]" routerLinkActive="router-link-active" ><i class="fa fa-plus"></i><i class="fa fa-question"></i></button>
              <button class="btn btn-outline-primary w-100" [routerLink]="['/classifications/edit/', classification.idClassification]" routerLinkActive="router-link-active" ><i class="fa fa-pencil"></i></button>
              <button class="btn btn-outline-info w-100" [routerLink]="['/classifications/detail/', classification.idClassification]" routerLinkActive="router-link-active" ><i class="fa fa-info"></i></button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

