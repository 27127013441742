import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Classification } from 'src/app/_models/Classification';
import { Level } from 'src/app/_models/Level';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-classification-edit',
  templateUrl: './classification-edit.component.html',
  styleUrls: ['./classification-edit.component.scss']
})
export class ClassificationEditComponent implements OnInit {
  updateForm: FormGroup;
  Levels: Level[];
  classification: Classification;

  constructor(private router: Router, private route: ActivatedRoute,
              private examService: ExamService, private alertify: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {

    this.route.data.subscribe(data => {
      this.Levels = data.Levels;
    });

    this.route.data.subscribe(data => {
      this.classification = data.classification;
    });

    this.createUpdateForm();
  }

  createUpdateForm() {
    this.updateForm = this.fb.group({
      Active: [this.classification.Active, Validators.required],
      ClassificationName: [this.classification.ClassificationName, Validators.required],
      ClassificationDescription: [this.classification.ClassificationDescription, Validators.required],
      idLevel: [this.classification.idLevel, Validators.required],
      ClassificationOrder: [this.classification.ClassificationOrder, Validators.required]
    });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  updateClassification(){
    this.examService.UpdateClassification(this.classification).subscribe(() => {
      this.alertify.success('Classificacion Actualizada');
      this.router.navigate(['/classifications']);
    }, error => {
      this.alertify.error('Ocurrio un error al actualizar');
      this.alertify.error(error);
    }, () => {
    });
    this.updateForm.reset(this.updateForm.value);
  }
}
