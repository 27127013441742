import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/User';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.apiUrl;

constructor(private http: HttpClient) { }

  getUser(id): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'User/' + id);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'User/GetUsers');
  }

  hasRol(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'User/HasRol/'+id);
  }

  UpdateUser(user: User){
    return this.http.post<boolean>(this.baseUrl + 'Examns/UpdateUser', user);
  }
}
