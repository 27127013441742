import { Injectable } from '@angular/core';
import { Course } from '../_models/Course';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CourseService } from '../_services/course.service';

@Injectable()
export class ListCoursesResolver implements Resolve<Course[]> {
    constructor(private courseService: CourseService, private router: Router,
                private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Course[]> {
        return this.courseService.GetAllCourses(1).pipe(
            catchError(error => {
                this.alertify.error('problem retriving data');
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}