<div class="container mt-4 mb-5">
  <h3 class="mt-3">Examen a Presentar {{exam.Name}}</h3>
  <h5 class="mt-5">Descripción</h5>
  <p class="mt-1">{{exam.Description}}</p>
  <div class="row">
    <div class="col">
      <div class="w-100 btn-group d-felx justify-content-center">
        <button class="btn btn-outline-secondary" style="max-width: 120px;" type="button" (click)="cancel()">Cancelar</button>
        <button class="btn btn-outline-success ml-2" style="max-width: 120px;" [routerLink]="['/presentexam/', idUserExams]" routerLinkActive="router-link-active" >Presentar <i class="fa fa-pencil-square-o"></i></button>
      </div>
    </div>
  </div>
</div>



