<div class="text-center mt-5">
  <div class="center_div">
    <div class="container">
      <form [formGroup]="updateForm" (ngSubmit)="updateLevel()">
        <h2 class="text-center text-primary">Editar Nivel</h2>
        <hr>
      
        <div class="form-group">
          <input type="text" [(ngModel)]="Level.LevelName"
          [ngClass]="{'is-invalid': updateForm.get('LevelName').errors && updateForm.get('LevelName').touched }"
          class="form-control" formControlName="LevelName" placeholder="Nombre del Nivel">
          <div class="invalid-feedback">Porfavor ingrese el nombre del Nivel</div>
        </div>

        <div class="form-group">
          <textarea [(ngModel)]="Level.LevelDescription" class="form-control"  rows="3" [ngClass]="{'is-invalid': updateForm.get('LevelDescription').errors && updateForm.get('LevelDescription').touched }"
          class="form-control" formControlName="LevelDescription" placeholder="Descripción"></textarea>
          <div class="invalid-feedback">Porfavor agregue una descripción</div>
        </div>
        
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input [(ngModel)]="Level.Active" formControlName="Active" type="checkbox" class="custom-control-input" id="customSwitches">
            <label class="custom-control-label" for="customSwitches">Activo</label>
          </div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-success" [disabled]="!updateForm.dirty || !updateForm.valid" type="submit">Actualizar</button>
          <button class="btn btn-default" type="button" [routerLink]="['/levels']">Cancelar</button>
        </div>
      </form>
    </div>
  </div>
</div>
