import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Exam } from '../_models/Exam';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { ExamService } from '../_services/exam.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-Exams',
  templateUrl: './Exams.component.html',
  styleUrls: ['./Exams.component.scss']
})
export class ExamsComponent implements OnInit {
  exams: Exam[];
  paymentMode: boolean;
  selectedIdExam: number;
  hideActions: boolean;

  constructor(public authService: AuthService, private userService: UserService
              , private examService: ExamService, private route: ActivatedRoute,
               private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.data.subscribe( data => {
      this.exams = data['Exams'];
    });
    this.hasRol();
  }

  hasRol() {
    this.userService.hasRol(this.getUserId()).subscribe((hide: boolean) => {
      this.hideActions = hide;
    });
  }

  getUserId(): number {
    return this.authService.getUserId();
  }

  paymentToggle(idExam: number) {
    this.selectedIdExam = idExam;
    this.paymentMode = true;
  }

  cancelPaymentMode(paymentMode: boolean) {
    this.paymentMode = paymentMode;
  }

  onChangeActiveFilter(value){
    this.examService.getExams(value).subscribe((exams: Exam[]) => {
      this.exams = exams;
    }, error => {
      this.alertify.error(error);
    });
  }
}
